.cron_builder_bordering {
    border: 1px solid #ddd;
    border-top: none;
    text-align: center;
    padding: 10px;
    background: #fff;
}

.cron_builder_bordering input, .cron_builder_bordering select {
    width: 100px;
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    padding-left: 5px;
    cursor: pointer;
}

.df {
    display: flex;
}

.cron-builder-bg {
    background-color: #008ad8;
    color: #fff;
    text-align: center;
    margin-bottom: 4px;
    padding: 8px 0px;
}

.cron_builder_bordering select {
    background-color: white;
    width: 75px;
    cursor: pointer;
    padding: 4px 0px;
    border-radius: 4px;
}

.cron_builder_bordering select option:hover {
    background-color: #008ad8;
}

.well-small {
    text-align: left;
    margin: 10px 0;
}

.well-small input {
    width: auto !important;
    color: #6A6A69;
}

.hours {
    width: 100px !important;
    display: inline-block;
}

.minutes {
    width: 100px !important;
    display: inline-block;
}

.cron_builder_bordering input[type='radio'] {
    margin-top: 0px;
    vertical-align: middle;
}

.cron_builder {
    border: 1px solid #d0cbcb;
    padding: 5px;
    background-color: #eee;
}

.text_align_left {
    text-align: left;
}

.text_align_right {
    text-align: right;
}

.tab-pane {
    text-align: left;
}

.cron_builder .nav li {
    cursor: pointer;
    display: inline-block;
    padding: 8px;
    border: 1px solid #008ad8;
    margin-right: 10px;
}

.cron_builder .nav li.active {
    background-color: #008ad8;
    color: #fff;
}