:root {
    --white: #fff;
    /*--primary: #3a3a3a;*/
    --light: #f8f8f8;
    /*--muted: #909090;*/
    --muted: #6b7280;
    --border: #d7d7d7;
    --link: #236591;
    --button: #00365A;
    --buttonHover: #001e31;
    --icon: #8f8f8f;
    --input: #fff;
    --tableBorder: #f3f3f3;
    --inputDarker: #e9ecef;
    --darkblue: #00365a;
    --inverseRGB: rgba(255, 255, 255, 1);
    --inverseRGBTransparent: rgba(255, 255, 255, 0);
}

body {
    min-height: 100vh;
}

/*html,*/
/*body {*/
/*    color: var(--primary);*/
/*    background-color: var(--light);*/
/*    transition: .3s background-color;*/
/*}*/

/*.card,*/
/*.navbar,*/
/*.dropdown-menu,*/
/*.background-light,*/
/*.has-float-label > span::after {*/
/*    transition: .3s background-color;*/
/*}*/

/*.separator,*/
/*.border-bottom,*/
/*table.dataTable td,*/
/*footer.page-footer,*/
/*.nav-tabs.separator-tabs {*/
/*    transition: .3s border-color;*/
/*}*/
.separator-left {
    border-left: 1px solid #d7d7d7;
}

/*.app-menu,*/
/*.conversation,*/
/*.form-control,*/
/*.navbar .search,*/
/*table.dataTable td,*/
/*.chat-container-custom,*/
/*.menu .main-menu ul li a,*/
/*.chat-input-container-custom,*/
/*.page-item.disabled .page-link,*/
/*.menu .main-menu.default-transition,*/
/*.menu .sub-menu.default-transition {*/
/*    transition: .3s all;*/
/*}*/

a,
.card-body.btn,
.menu .main-menu ul li span {
    color: var(--primary);
}

.input-group-text {
    background-color: var(--white);
    border-color: var(--border);
    color: var(--primary);
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    background: var(--white);
    color: var(--primary);
}

.search-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex justify-between items-center-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.weather-content {
    font-size: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.weather-content span {
    font-size: 13px;
}

.transparent-card {
    width: 100%;
    height: 100%;
    /*background: rgba(255,255,255, 0.4);*/
    border-radius: 14px;
}

.weather-title {
    font-size: 25px;
    text-align: center;
    padding: 10px;
}

.search-box {
    width: 50%;
    position: relative;
    margin: auto;
}

.search-box .search {
    width: 100%;
    border: none;
    padding: 10px;
    color: #fff;
    background: transparent;
    border-bottom: 1px solid #fff;
    text-align: center;
}

.search-box i {
    font-size: 16px;
    color: #fff;
    position: absolute;
    right: -20px;
    bottom: 50%;
}

.search-box .search::placeholder {
    color: #fff;
}

.search-box .search:focus {
    outline: none;
}

.color-primary {
    color: var(--primary) !important;
}

.color-darkblue {
    color: var(--darkblue) !important;
}

.color-button {
    color: var(--button) !important;
}

.card-body.btn:hover {
    color: var(--link);
}

.navbar .menu-button-mobile:hover {
    cursor: pointer;
}

.navbar .menu-button-mobile svg {
    fill: var(--primary);
}

.btn-focusable:focus {
    background: var(--buttonHover) !important;
    color: var(--white) !important;
}

.color-white {
    color: var(--white) !important;
}

/*@media (max-width: 1400px) {*/
/*    .sticky-bottom-footer {*/
/*        position: fixed;*/
/*        bottom: 0;*/
/*        background: #fff;*/
/*        width: 100%;*/
/*        left: 110px;*/
/*        padding: 1em 1em 1em 4em;*/
/*        z-index: 10;*/
/*    }*/
/*}*/

.sticky-bottom-footer {
    position: fixed;
    bottom: 0;
    background-color: var(--white);
    width: 100%;
    left: 0px;
    padding: 1em 60px 1em 60px;
    border-top: 1px solid var(--border);
    z-index: 10;
    height: 63px;
    transition: transform .3s, background .3s;
}

.sticky-footer-padding {
    padding: 1em 0 1em 0;
}

.sticky-bottom-footer::before {
    content: "";
    position: absolute;
    background-color: var(--white);
    width: 10px;
    height: 10px;
    left: -10px;
    bottom: 0;
    transition: background .3s;
}

.main-hidden .sticky-bottom-footer {
    transform: translateX(-90px);
}

@media only screen and (max-width: 1449px) {
    .sticky-bottom-footer {
        padding: 1em 40px 1em 40px;
    }
}

@media only screen and (max-width: 767px) {
    .sticky-bottom-footer {
        padding: 1em 15px 1em 15px;
    }
}

a:hover,
a:active,
.menu .main-menu ul li:hover span {
    color: var(--button);
}

.color-darkorange {
    color: darkorange !important;
}


.navbar,
.menu .sub-menu {
    background: var(--white);
}

.navbar .header-icon {
    color: var(--icon);
}

.navbar .menu-button .main,
.navbar .menu-button .sub {
    fill: var(--primary);
}

.card,
.menu .main-menu,
.menu .sub-menu {
    background-color: var(--white);
}

.menu-icon,
.submenu-icon {
    fill: var(--primary);
}

.menu li.active .menu-icon,
.menu li.active .submenu-icon,
.menu li:hover .menu-icon,
.menu li:hover .submenu-icon {
    fill: var(--button);
}

.active .submenu-icon,
.menu .main-menu ul li.active span,
.menu .sub-menu ul li.active i,
.menu .sub-menu ul li.active a {
    fill: var(--link);
    color: var(--link);
}

.navbar .search input {
    color: var(--primary);
}

.has-link {
    color: var(--link) !important;
    font-weight: 600;
}

.focus\:btn:focus {
    box-shadow: rgb(6, 182, 212) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
}

/*.btn-primary {*/
/*    color: #fff;*/
/*    background-color: var(--button);*/
/*    border-color: var(--button);*/
/*}*/

/*.btn-primary:hover {*/
/*    color: #fff;*/
/*    background-color: var(--buttonHover);*/
/*    border-color: var(--buttonHover);*/
/*}*/

.btn-outline {
    color: var(--link);
    border-color: var(--link);
}

.btn-outline:hover {
    color: var(--white);
    background-color: var(--link);
    border-color: var(--buttonHover);
}

.page-item.disabled .page-link {
    color: var(--border);
    border-color: var(--border);
}

.search-sm input,
.btn-outline-dark {
    border-color: var(--icon);
    color: var(--primary);
}


.input-like {
    color: var(--primary);
    background-color: var(--input) !important;
    border-color: var(--border) !important;;
}

.dropdown-menu {
    background-color: var(--input);
    border-color: var(--border);
}

.dropdown-item {
    color: var(--primary);
}

.border-bottom {
    border-color: var(--tableBorder) !important;
}

.nav-tabs.separator-tabs {
    border-color: var(--border);
}

.dropdown-item:hover {
    background-color: var(--light);
    color: var(--primary);
}

.nav-tabs .nav-link.active {
    color: var(--button);
}

.nav-tabs.separator-tabs .nav-link {
    padding-left: 0.2em;
    padding-right: 0.2em;
}

.nav-tabs.separator-tabs .nav-link.active::before {
    background-color: var(--button);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color: var(--link);
}

.navbar .search {
    background-color: var(--light);
}

.navbar .search .search-icon {
    color: var(--border);
}

.separator,
footer.page-footer,
.menu .main-menu ul li a,
.menu .sub-menu {
    border-color: var(--border);
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input + .custom-switch-btn {
    border-color: var(--border);
    background-color: var(--border);
}

.custom-switch .custom-switch-input + .custom-switch-btn::after {
    background-color: var(--white);
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked + .custom-switch-btn {
    border-color: var(--link);
    background: transparent !important;
}

.custom-switch-btn-disabled,
.custom-switch-btn-disabled::after {
    border-color: var(--border) !important;
    background-color: var(--border) !important;
}

.custom-switch-input {
    display: block !important;
    position: absolute;
    opacity: 0;
}

/*.has-float-label > span {*/
/*    color: var(--primary) !important;*/
/*}*/

/*.has-float-label label::after, .has-float-label > span::after {*/
/*    background-color: var(--input);*/
/*}*/

.table {
    color: var(--primary);
}

table.dataTable td,
.border-top {
    border-color: var(--tableBorder) !important;
}

table.table-equalize-height td {
    box-sizing: content-box !important;
}

table.dataTable .tr-archived td {
    border-color: var(--light);
}

.text-tm-gray-500 {
    color: var(--muted) !important;
}

.btn-link,
footer.page-footer .breadcrumb-item + .breadcrumb-item::before {
    color: var(--link);
}

.btn-link:hover {
    color: var(--button);
    opacity: .7;
}

.page-item .page-link {
    color: var(--primary);
}

.page-item.active .page-link {
    color: var(--button);
    border-color: var(--button);
}

.page-item .page-link:hover {
    border-color: var(--link);
    color: var(--link);
}

.modal .modal-content {
    background-color: var(--white);
}

.modal .modal-header,
.modal .modal-footer {
    border-color: var(--border)
}

.close,
.modal .close {
    color: var(--primary);
    text-shadow: initial;
}

.modal .close:hover,
.modal .close:focus {
    color: var(--link);
    border: var(--link);
}

input[type="checkbox"]:focus + label:after {
    box-shadow: rgb(6, 182, 212) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
    border-radius: .25rem;
}

.checkbox-disabled:hover {
    cursor: not-allowed !important;
}

/* Put utility/helper classes below */
.h-screen {
    height: 100vh;
}

.min-h-screen {
    min-height: 100vh;
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.overflow-y-auto {
    overflow-y: auto;
}

.h-6 {
    height: 1.5rem;
}

.w-6 {
    width: 1.5rem;
}

.w-50px {
    width: 50px;
}

.w-20rem {
    width: 20rem;
}

.-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
}

.font-16px {
    font-size: 16px;
}

.border-bottom-1 {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.icon-border::before {
    color: var(--border)
}

.top-0 {
    top: 0;
}

.top-1px {
    top: 1px;
}

.top-2em {
    top: 2em !important
}

.top-full {
    top: 100%;
}

.bottom-14 {
    bottom: 3.5em;
}

.right-0 {
    right: 0;
}

.right-1em {
    right: 1em;
}

.right-1\.5em {
    right: 1.5em;
}

.left-auto {
    left: auto !important;
}

.inset {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.transition-height {
    transition: .3s height;
}

.flip-x {
    transform: rotateX(180deg);
}

.flip-y {
    transform: rotatey(180deg);
}

.un-flip-y {
    transform: rotatey(0deg);
}

.modal-2xl {
    max-width: 1400px;
}

.no-wrap {
    white-space: nowrap;
}

.clear-position {
    position: relative;
}

.clear-position svg {
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 10px;
    right: 0;
    z-index: 1;
    cursor: pointer;
    fill: var(--primary);
}

.position-x-auto {
    right: auto !important;
    left: auto !important;
}

.position-right {
    right: 0;
    left: auto;
}

.position-top {
    bottom: auto;
    top: 0
}

.border-solid {
    border-style: solid !important;
}

.border-color {
    border-color: var(--border) !important;
}

.border-color-table {
    border-color: var(--tableBorder)
}

.border-color-button {
    border-color: var(--button)
}

.border-bottom-white {
    border-bottom-color: var(--white) !important;
}

.border-w-1 {
    border-width: 1px !important;
}

.separator-v {
    height: 36px;
    margin-top: 4px;
    margin-left: .5em;
    margin-right: .5em;
    width: 1px;
    background-color: var(--border);
}

.hover\:border-solid:hover {
    border-style: solid !important;
}

.line-height-normal {
    line-height: normal !important;
}

.child-h-100 > * {
    height: 100% !important;
}

.hover-white:hover {
    color: #fff
}

.table-row-h-50 td {
    height: 50px;
    box-sizing: border-box !important;
}

.w-1\.5em {
    width: 1.5em;
}

.w-2em {
    width: 2em;
}

.h-2em {
    height: 2em;
}

.w-3em {
    width: 3em;
}

.w-10em {
    width: 10em;
}

.w-15em {
    width: 15em;
}

.w-20em {
    width: 20em;
}

.h-1\.5em {
    height: 1.5em;
}

.h-3em {
    height: 3em;
}

.top-2 {
    top: 2em;
}

.pl-3em {
    padding-left: 3em;
}

@media (max-width: 575px) {
    .sm\:w-3em {
        width: 3em;
    }

    .sm\:h-3em {
        height: 3em;
    }
}


.left-1 {
    left: 1em;
}

.row-height {
    min-height: 64px;
}


.two-rows-textarea-height {
    min-height: 96px !important;
}

.three-rows-textarea-height {
    min-height: 112px !important;
}

.canvas-z-1 > canvas {
    z-index: 1;
    position: relative;
}

.hover\:bg-primary:hover {
    background-color: var(--link) !important;
    color: #fff;
}

.hover\:bg-primary:hover .text-tm-gray-500 {
    color: #fff !important;
}

.hover\:bg-input:hover {
    background-color: var(--input) !important;
}

hover\:opacity-100 {
    opacity: 100;
}

.rounded-12 {
    border-radius: 12px !important;
}

.border-color-button {
    border-color: var(--button) !important;
}

.transition {
    transition: .3s transform;
}

.drag-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    /*background-color: red;*/
}

.icon-width {
    width: 20px;
}

.icon-white svg {
    fill: #fff !important;
}

.min-w-50 {
    min-width: 50px;
}

.row-hover:hover {
    cursor: pointer;
    color: var(--link);
}

.row-hover > td:first-child {
    border-left: 4px solid transparent !important;
}

.row-hover:hover > td:first-child {
    border-left: 4px solid var(--link) !important;
}

.background-light {
    background: var(--light);
}

.background-white {
    background: var(--white) !important;
}


.alert-icon {
    opacity: .6;
    position: absolute;
    left: .7em;
    top: 50%;
    transform: translateY(-50%);
}

.row-expand {
    animation-name: rowExpand;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    position: relative;
    z-index: 6;
}

.checkbox-height {
    height: 64px;
}

.text-area-height {
    min-height: 103px !important;
}

.col-3xl-6, .col-2xl-6, .col-2xl-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media only screen and (min-width: 1450px) {
    .col-2xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-2xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}

@media only screen and (max-width: 1449px) {
    .mb-2xl-5 {
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 1700px) {
    .col-3xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-3xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}

@media only screen and (max-width: 1699px) {
    .mb-3xl-5 {
        margin-bottom: 2rem;
    }
}

@keyframes rowExpand {
    0% {
        height: 0;
        opacity: 0
    }
    99% {
        height: 64px;
        opacity: 1
    }
    100% {
        height: auto
    }
}

.table .thead-light th {
    background-color: var(--light) !important;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none;
    user-select: none;
}

.main-bg {
    margin: 0 !important;
    height: 100vh !important;
}

.bg-light {
    background-color: var(--light) !important;
}

.bg-bali-hai {
    background-color: #8a9fb4;
}

.bg-black {
    background: #1b1e21;
}

.fill-muted > svg {
    fill: var(--muted) !important;
}

.transition-height {
    transition: .3s height;
}

.transition {
    transition: all .3s;
}

.hover\:bg-primary:hover svg {
    fill: #fff !important;
}

.text-medium {
    font-size: 20px !important;
}

.main-bg .container {
    height: 100%;
}

.action-container {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
}

.action-container-center {
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    position: fixed;
}

.action-loader:after {
    content: " ";
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba(105, 105, 105, 0.3);
    border-top-color: rgba(105, 105, 105, 0.3);
    border-radius: 50%;
    border-top-color: rgba(105, 105, 105, 0.6);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    position: absolute;
    z-index: 1;
}

.fixed-background {
    border-radius: 0 !important;
}

.login-bg {
    background: #c8d2dc !important;
}

.dark .login-bg {
    background: #0f172a !important;
}

.auth-card .image-side {
    background: url("../assets/images/login/truck.jpg") no-repeat center center;
}

@media (max-width: 991px) {
    .auth-card .image-side {
        background-position: center top;
    }
}


#app-container.sub-hidden .sub-menu,
#app-container.menu-sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
    transform: translateX(-160px);
}

.menu {
    margin-left: 300px;
}

.menu .main-menu ul li.active {
    border-left: 4px solid var(--button);
}

.menu .main-menu ul li.active::after {
    display: none;
}

.menu .main-menu {
    width: 90px;
}

.menu .main-menu ul li a {
    height: 90px;
}

.menu .sub-menu {
    left: 90px;
}

.menu .sub-menu {
    width: 160px;
}

.main-menu {
    overflow-y: auto;
    overflow-x: hidden;
}

.sub-menu-h-1 {
    margin-top: 97px;
}

.sub-menu-h-2 {
    margin-top: 194px;
}

.sub-menu-h-3 {
    margin-top: 291px;
}

.sub-menu-h-4 {
    margin-top: 378px;
}

.sub-menu-h-5 {
    margin-top: 475px;
}

.sub-menu-h-6 {
    margin-top: 572px;
}

.sub-menu-h-7 {
    margin-top: 669px;
}

.sub-menu-h-8 {
    margin-top: 776px;
}

@media screen and (max-height: 1000px) {
    .sub-menu-h-1 {
        margin-top: 67px;
    }

    .sub-menu-h-2 {
        margin-top: 134px;
    }

    .sub-menu-h-3 {
        margin-top: 201px;
    }

    .sub-menu-h-4 {
        margin-top: 268px;
    }

    .sub-menu-h-5 {
        margin-top: 335px;
    }

    .sub-menu-h-6 {
        margin-top: 402px;
    }

    .sub-menu-h-7 {
        margin-top: 469px;
    }

    .sub-menu-h-8 {
        margin-top: 536px;
    }
}

@media screen and (max-height: 720px) {
    .sub-menu-h-1,
    .sub-menu-h-2,
    .sub-menu-h-3,
    .sub-menu-h-4,
    .sub-menu-h-5,
    .sub-menu-h-6,
    .sub-menu-h-7,
    .sub-menu-h-8,
    .sub-menu-h-9 {
        margin-top: 5px;
    }
}

#app-container.menu-sub-hidden main {
    margin-left: 150px;
}

#app-container.main-hidden main {
    margin-left: 50px;
}

.submenu-indicator {
    position: absolute;
    right: -8px;
    transition: .3s all;
    opacity: 0;
    color: var(--link)
}

.submenu-indicator i {
    font-size: 15px !important;
}

.submenu-indicator-visible {
    opacity: .8;
}

.main-hidden .submenu-indicator {
    display: none;
}

.triangle-right {
    width: 0;
    height: 0;
    position: relative;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid var(--link);
    border-right: 0 solid var(--link);
    box-sizing: border-box;
    display: inline-block;
    transition: .3s all;
    opacity: .8;
}

.submenu-indicator-active .triangle-right {
    transform: translateX(-8px) rotate(180deg);
}

.logo,
.logo-single {
    background-image: url("../assets/images/logo.png");
    background-size: 100%;
    width: 200px;
    height: 53px;
}

.relative {
    position: relative;
}

.under {
    z-index: -1;
}

.modal {
    display: block !important;
}

.hover:hover,
.page-link:hover {
    cursor: pointer;
}


.has-link:hover {
    color: var(--primary) !important;
}

.register-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    font-weight: 400 !important;
}

/* Forms */


.field-checkbox-align {
    min-height: 40px;
}

/* Select CSS */
.select-css-search {
    position: relative;
    padding: 0 !important;
    background-color: transparent !important;
}

/*.select-css-search > div:not(.css-weoab-menu) {*/
/*    position: relative;*/
/*    top: 0;*/
/*    left: 0;*/
/*    border: none!important;*/
/*    padding: 0;*/
/*    width: 100%;*/
/*    border-radius: 0;*/
/*}*/

/*.select-css-search > div:nth-child(3) {*/
/*    padding: 0;*/
/*    width: 100%;*/
/*    border-radius: 0;*/
/*}*/

.invalid-feedback {
    display: block;
}

.animation-element-fade-in {
    animation-name: elementFadeIn;
    animation-duration: .3s;
}

@keyframes elementFadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.anim-fade-in {
    animation-name: backdropFadeIn;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

@keyframes backdropFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: .35;
    }
}

@keyframes backdropFadeOut {
    from {
        opacity: .35;
    }
    to {
        opacity: 0;
    }
}

.anim-fade-out {
    animation-name: backdropFadeOut;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

.anim-drop-down {
    animation-name: modalDropDown;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

@keyframes modalDropDown {
    from {
        transform: translateY(-100px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.anim-up {
    animation-name: modalUp;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

@keyframes modalUp {
    from {
        transform: translateY(0);
        opacity: 1
    }
    to {
        transform: translateY(-100px);
        opacity: 0
    }
}

.sticky-table-fade-in {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: var(--white);
    animation-fill-mode: forwards;
    animation-name: tableFadeInAlt;
    animation-duration: 1s;
    z-index: 9;
}

@keyframes tableFadeInAlt {
    0% {
        opacity: 1;
        height: 100%
    }
    99% {
        opacity: 0;
        height: 100%
    }
    100% {
        opacity: 0;
        height: 0
    }
}

.table-fade-in td,
.table-fade-in th {
    animation-name: tableFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes tableFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.table-fade-out {
    animation-name: tableFadeOut;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes tableFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.loader-center {
    position: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}


/* TABLE for Office page */

.align-items-top {
    align-items: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.updateOfficeDialog {
    position: absolute;
    overflow-y: scroll;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

}

.deleteOfficeDialog {
    position: absolute;
    width: 500px;
    height: 200px;
    background: #fff;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    z-index: 1;
    border: 1px solid black;
    border-radius: 10px;
}

.inner-info {
    padding: 20px;
}

/* INFO SCROLL BAR */
/* width */
/*.updateOfficeDialog::-webkit-scrollbar {*/
/*    width: 20px;*/
/*  }*/
/*  */
/*  !* Track *!*/
/*  .updateOfficeDialog::-webkit-scrollbar-track {*/
/*    box-shadow: inset 0 0 5px grey; */
/*    border-radius: 30px;*/
/*  }*/
/*   */
/*  !* Handle *!*/
/*  .updateOfficeDialog::-webkit-scrollbar-thumb {*/
/*    background: black; */
/*    border-radius: 30px;*/
/*  }*/
/*  */
/*  !* Handle on hover *!*/
/*  .updateOfficeDialog::-webkit-scrollbar-thumb:hover {*/
/*    background: black; */
/*  }*/

.flex justify-between items-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.display-flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.display-flex-start-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.display-flex-start-top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.header-search {
    position: relative;
    bottom: 5px;
}

#notificationDropdown .scroll {
    overflow-y: hidden;
}

#notificationDropdown .scroll {
    overflow-y: auto;
}

.navbar #notificationDropdown {
    width: 300px;
}

.btn:not(:disabled) {
    cursor: pointer;
}

.btn.disabled {
    cursor: not-allowed !important;
    background: gray !important;
    border: 1px solid gray !important;
    color: white !important;
}

.btn.disabled.btn-outline {
    background: var(--white) !important;
    color: var(--muted) !important;
    border: 1px solid var(--muted) !important;
}

.btn.disabled.btn-primary {
    background: var(--muted) !important;
    color: var(--white) !important;
    border: 1px solid var(--muted) !important;
}

.btn-focus:focus,
.btn-focus:hover {
    border: 1px solid var(--button)
}

.btn-pagination {
    min-width: 30px;
    height: 30px;
    font-size: 0.76rem;
    border-radius: 50%;
    border: 1px solid var(--link);
}

.btn-pagination i {
    line-height: 1.3;
}

.btn-pagination.disabled {
    opacity: .5;
}

.btn-pagination:not(.disabled) {
    background-color: var(--button);
    color: #fff;
}

.focus\:btn-pagination:focus {
    background-color: var(--link);
}

.focus\:btn-pagination:focus span {
    color: #fff !important;
}

.disabled i {
    cursor: not-allowed !important;
}

.disabled {
    cursor: not-allowed !important;
}

.badge-disabled {
    opacity: .9;
    background-color: var(--border) !important;
}

.bg-gray div {
    background: lightgray !important;
    cursor: not-allowed !important;
}

.badge-outline-info.badge-disabled,
.badge-outline-success.badge-disabled,
.badge-outline-primary.badge-disabled,
.badge-outline-danger.badge-disabled {
    opacity: .3;
}

.pointer-events-none {
    pointer-events: none;
}

a, li, i {
    cursor: pointer;
}

.excel-image {
    width: 40px;
    cursor: pointer;
}

.fontsize-1-1 {
    font-size: 1.2rem;
    margin-left: 10px;
}

.font-size-16 {
    font-size: 16px;
}

.mapa {
    width: 100%;
    height: 100%;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

.map-leaflet-container {
    width: 100%;
    /*height: 400px;*/
    /*height: calc(100vh-320px);*/
    height: calc(100vh - 320px - 4rem);
}

.vehicles {
    height: calc(100vh - 320px - 4rem);
    overflow-y: scroll;
}

.trucks {
    height: calc(100vh - 320px - 4rem);
}

.full-display {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw !important;
    height: 90vh !important;
}

.phone-label {
    position: relative;
    top: -17px;
    left: 10px;
    padding-left: .2em;
    padding-right: .2em;
    background-color: #fff;
}

.icon-close {
    line-height: 32px;
}

.z-index {
    z-index: 1;
}

.z-index-3 {
    z-index: 3 !important;
}

.z-index-10 {
    z-index: 10;
}

.z-index-15 {
    z-index: 15;
}

.z-index-100 {
    z-index: 100;
}

.z-index-1040 {
    z-index: 1040;
}

.company-image {
    height: 50px;
}

.navbar .company-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.td-actions-2 {
    min-width: 90px;
    width: 90px;
}

.td-actions-width-3 {
    width: 145px;
}

.td-actions-width-4 {
    width: 200px;
}

/*.tr-archived {*/
/*    background-color: var(--light) !important;*/
/*}*/

.tr-archived td {
    position: relative;
    padding-bottom: 22px;
    background-color: var(--light) !important;
}

.tr-archived td::before {
    content: "";
    position: absolute;
    background-color: var(--light);
    top: -3px;
    left: -10px;
    height: calc(100% + 4px);
    width: 10px;
    z-index: 1;
}

.tr-archived td::after {
    content: "";
    position: absolute;
    background-color: var(--light);
    z-index: 1;
    top: -3px;
    left: -10px;
    height: 3px;
    width: calc(100% + 10px);
}

.tr-archived:last-child td::before {
    height: calc(100% + 3px);
}

.table-align-middle td {
    vertical-align: middle;
}

.table-min-width {
    min-width: 1060px;
}

.sticky-table {
    position: relative;
}

.sticky-table td {
    height: 35px;
}

.sticky-table th:first-child,
.sticky-table td:first-child {
    left: 0;
    min-height: 60px;
    position: absolute !important;
    top: auto;
    width: 160px;
    box-sizing: border-box;
    /*overflow: hidden;*/
}

.sticky-table td:first-child {
    display: flex;
    align-items: center;

}

.sticky-table th:last-child,
.sticky-table td:last-child {
    right: 0;
    min-height: 60px;
    position: absolute;
    top: auto;
    width: 185px;
    max-width: 100%;
    box-sizing: border-box;
}

.sticky-table td:last-child.td-actions-width-4 {
    width: 200px;
}


.sticky-table-scroller {
    margin-left: 160px;
    margin-right: 145px;
    overflow-x: visible;
    overflow-y: auto;
    padding-bottom: 5px;
    width: calc(100% - 350px);
}

.sticky-table-scroller-scrollable-r {
    border-right: 1px dashed var(--border);
    position: absolute;
    top: 0;
    right: 189px;
    bottom: 2em;
    width: 4em;
    background: linear-gradient(90deg, var(--inverseRGBTransparent) 0%, var(--inverseRGB) 90%);
    z-index: 1;
}

@media (max-width: 992px) {
    .sticky-table-scroller-scrollable-r {
        right: 0;
    }

    .sticky-table-scroller {
        width: calc(100% - 120px);
        margin-left: 120px;
        margin-right: 0;
    }

    .sticky-table th:first-child,
    .sticky-table th:last-child {
        width: 120px;
    }

    .sticky-table th:last-child,
    .sticky-table td:last-child {
        position: relative;
    }

    .sticky-table th:first-child,
    .sticky-table td:first-child {
        width: 120px;
    }
}

.infinite-scroll-component__outerdiv thead th {
    position: sticky;
    top: 0;
    background: var(--color-inverse);
}

.display-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.content {
    transition: 1s ease;
}

.collapse-modal {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;

}

.expand-modal {
    max-height: 4000px;
    transition: max-height 0.25s ease-in;
}

.excel-wrapper {
    height: 30px;
}

.position-absolute-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-absolute-cover {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.contact-avatar {
    min-height: 167px;
}

.simple-icon-size-fullscreen:before {
    content: "\e057";
}

.simple-icon-size-actual:before {
    content: "\e058";
}

.btn-remove-styles {
    background: transparent;
    border: 0;
    padding: 0;
}

.btn-outline-theme-3 {
    color: var(--buttonHover);
    border-color: currentColor;
}

.btn-outline-theme-3.disabled {
    opacity: .2;
}

.sorting {
    vertical-align: top;
}

table thead .sorting::after,
table thead .sorting_asc::after,
table thead .sorting_desc::after {
    font-size: 17px;
    top: 7px;
    right: 1em !important;
}

table thead .sorting::before,
table thead .sorting_asc::before,
table thead .sorting_desc::before {
    font-size: 17px;
    top: 7px;
    right: .5em !important;
}

table.data-table td,
table.dataTable td,
table.data-table th,
table.dataTable th {
    padding: 12px;
}

tbody {
    word-break: break-all;
}

/*.info-hover td {*/
/*    position: relative;*/
/*}*/

/*.info-hover:hover td {*/
/*    background: var(--light);*/
/*}*/

/*.info-hover:hover td::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    background-color: var(--light);*/
/*    top: -3px;*/
/*    left: -10px;*/
/*    height: calc(100% + 3px);*/
/*    width: 10px;*/
/*}*/

/*.info-hover:hover td::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    background-color: var(--light);*/
/*    top: -2px;*/
/*    left: -10px;*/
/*    height: 2px;*/
/*    width: calc(100% + 10px);*/
/*}*/


.height {
    width: 100%;
}

/*.has-link-element {*/
/*    color:#0099ff!important;*/
/*}*/

.height-transition {
    transition: all 300ms ease;
    overflow: hidden;
}

.icon-button {
    font-size: 15px;
}

.card-height-s {
    min-height: 225px;
}

.drop-down-zero {
    max-height: 0;
    transition: max-height .5s ease-out;
    overflow: hidden;
}

.drop-down-20-vh {
    max-height: 20vh;
    transition: max-height .5s ease-in;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Notifications */
.notification-fade-out {
    animation-name: notificationFadeOut;
    animation-duration: .5s;
    /*animation-delay: 6s;*/
    animation-fill-mode: forwards;
    overflow: hidden;
}

.notification-infinite {
    animation: none;
}

@keyframes notificationFadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        height: 0;
        border-top: 0;
        border-bottom: 0;
        opacity: 0
    }
}

@keyframes notificationFadeOutNow {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        height: 0;
        border-top: 0;
        border-bottom: 0;
        opacity: 0
    }
}

.notification-fade-out .close {
    display: none;
}

.notification-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 25px;
    bottom: 0;
}

/*scrollbar*/
/* width */
.custom-scroll-bar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.custom-scroll-bar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.custom-scroll-bar::-webkit-scrollbar-thumb {
    background: #d7d7d7;
    border-radius: 10px;
}

.grid-auto-fit-50 {
    display: grid;
    grid-gap: 5%;
    grid-template-columns: repeat( auto-fit, minmax(40%, 1fr) );
}

.grid-auto-fit-30 {
    display: grid;
    grid-gap: 5%;
    grid-template-columns: repeat( auto-fit, minmax(30%, 1fr) );
}

.pr-15px {
    padding-right: 15px;
}

.pl-15px {
    padding-left: 15px;
}

.avatar-image {
    width: 200px;
    min-width: 200px;
    min-height: 200px;
    border: 3px solid var(--border);
}

.avatar-image-placeholder {
    font-size: 36px;
    color: var(--border);
}

.avatar-image-placeholder:hover {
    cursor: default;
}

.avatar-image > img {
    max-width: 100%;
}

/*Documents*/
.document-icon {
    width: 24px;
    padding: 0;
}

.document-icon > svg {
    width: 100%;
}

.category-dropzone {
    height: 50px;
    background: transparent;
    position: relative;
    transition: .5s all;
}

.category-dropzone.active {
    background-color: rgba(0, 30, 49, .1) !important;
}

.max-img-size img {
    max-width: 100%;
}

.simple-icon-trash-hover {
    border-radius: 50%;
    transition: .3s all;
}

.simple-icon-trash-hover > i {
    transition: .3s all;
}

.simple-icon-trash-hover:hover {
    background-color: #00365a;
    color: #fff;
}

.list-item-heading .simple-icon-arrow-down {
    transition: .3s all;
    font-size: 14px !important;
    position: relative;
}

.list-item-heading .simple-icon-arrow-down::before {
    position: absolute;
    top: 2px;
    left: 0;
    transition: .3s all;
}

.icon-rotate:before {
    display: inline-block;
    transition: transform .3s;
    transform-origin: center;
}

.rotate-180:before {
    transform: rotate(-180deg);
}

.rotate-180 {
    transform: rotate(-180deg);
}

.accordion-loader {
    display: none;
    background-color: var(--white);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.accordion-button {
    width: 30px;
    height: 30px;
    background-color: var(--link);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: auto;
}

.show {
    display: block;
}

.collapsed {
    height: 0 !important;
}

.collapse-transition {
    transition: height .5s ease;
    overflow: hidden;
}

.search-sm-icon-remove::after {
    content: "";
}

.search-sm-icon-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}

.input-icons-left {
    right: auto;
    left: 0;
}

.input-icons-tall {
    height: 100%;
    top: 0;
    padding-top: 9px;
}

.input-icons-permissions {
    right: 40px;
}

.custom-draggable .form-control {
    padding-left: 30px;
}

/*For textarea height*/
.form-row-height {
    height: 40px !important;
}

.form-row-height-2 {
    height: 104px !important;
}

.form-row-height-3 {
    height: 168px !important;
}

.form-row-height-4 {
    height: 232px !important;
}

.form-row-height-5 {
    height: 296px !important;
}

/* Menu */
.menu-icon {
    display: inline-block;
    width: 52px;
    opacity: .8;
    margin-bottom: 5px;
}

.submenu-icon {
    display: inline-block;
    width: 16px;
    opacity: .8;
    margin-right: 1em;
}

.submenu-icon > svg {
    max-height: 18px;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker-popper {
    z-index: 10;
}

.border-dashed {
    border-style: dashed;
}

.border-solid {
    border-style: solid;
}

.border-hidden {
    border-color: transparent !important;
}

.border-t-0 {
    border-top: none !important;
}

.border-left-0 {
    border-left: none !important;
}

.border-right-0 {
    border-right: none !important;
}

.tab-remove-border-l:not(:first-child) {
    border-left: none !important;
}

.tab-remove-border-r:not(:last-child) {
    border-right: none !important;
}

.dropzone-full-height {
    min-height: calc(100% - 68px);
}

.dropzone-overlay {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.sortable[draggable="true"],
.sortable[draggable="true"] .form-control,
.sortable[draggable="true"] .simple-icon-cursor-move {
    cursor: grab;
}

.sortable .badge-outline-info,
.sortable .badge-outline-success,
.sortable .badge-outline-primary,
.sortable .badge-outline-danger {
    cursor: not-allowed !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.view-load-btn {
    border: 1px solid var(--link);
    padding: 7px 15px;
    border-radius: 20px;
    transition: .5s ease;
}

.view-load-btn:hover {
    background: var(--link);
    color: #fff;
}

.table-load {
    word-break: break-word;
}

.table-load th,
.table-load td {
    padding-left: .25em;
    padding-right: .25em;
    vertical-align: inherit;
}

.icon-load-details:hover {
    border-radius: 30px;
    background-color: var(--border);
}

.mt-1px {
    margin-top: 3px;
}

.mt-2rem {
    margin-top: 2rem;
}

.view-load-btn:hover {
    color: #fff !important;
}

.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    vertical-align: center;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.grid-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

.grid-trailers {
    grid-template-columns: 1fr 1fr 100px;
}


.table .head {
    font-weight: bolder;
    background: #f8f8f8;
    vertical-align: center;
    height: 40px;
}

.head p {
    margin-top: 10px;
    padding-left: 5px;
}

.add_icon {
    width: 10px;
    height: 10px;
    /*background: red;*/
    position: relative;
    margin-bottom: 10px;
}

.add_icon div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
}

.add_icon div:nth-child(1) {
    width: 2px;
    height: 100%;
}

.add_icon div:nth-child(2) {
    width: 100%;
    height: 2px;
}

.remove_icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(-50%, -5px);
    width: 10px;
    height: 10px;
    margin-bottom: 10px;
}

.remove_icon div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: black;
}

.remove_icon div:nth-child(1) {
    width: 2px;
    height: 100%;
}

.remove_icon div:nth-child(2) {
    width: 100%;
    height: 2px;
}

.users-icons .submenu-icon {
    width: 20px;
}

.users-icons,
.btn-input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease;
    color: #fff;
    height: 40px;
    /*font-size: 1.5rem;*/
    text-align: center;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    cursor: pointer;
    border: 0
}

.btn-input-group i {
    width: 26px;
    font-size: 20px;
}

.fs-1-5 {
    font-size: 1.5rem;
}

.users-icons:hover {
    background: #4e83a6;
}

.users-icons i {
    font-size: 20px;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-radius: 50px;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    border-radius: 50px;
}

.fc .fc-button-primary {
    border-radius: 50px;
    border-color: #00365a !important;
    background-color: var(--link) !important;
    outline: none !important;
    box-shadow: none !important;
    text-transform: capitalize;
}

.fc .fc-button-primary:not(:first-child) {
    margin-left: .5em;
}

.fc .fc-button-primary:not(:last-child) {
    margin-right: .5em;
}

.fc .fc-button-active {
    background-color: var(--primary) !important;
}

.svg-actions {
    min-height: 45px;
}

.svg-actions svg {
    width: 60%;
    fill: #616161;
}

.svg-fill-white svg {
    fill: #fff;
}

.fill-svg-hover:hover > .svg-actions,
.fill-svg-hover:hover > .svg-actions > svg {
    fill: #4e83a6;
}

.svg-hover-command:hover > .dropdown-menu-badge {
    color: #4e83a6;
    line-height: 16px !important;
}

.test {
    border-radius: 30px;
}

.goBack {
    width: 25px;
    height: 25px;
    background: #616161;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: .3s ease;
}

.goBack:hover {
    background: #4e83a6;
}

.goBack i {
    font-size: 10px;
    font-weight: bolder;
    color: #fff;
    margin-right: 2px;
}

.lookup-item {
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
    border-radius: 50px;
}

.selected-lookup {
    box-shadow: 0 1px 15px rgba(0, 0, 0, .1), 0 1px 6px rgba(0, 0, 0, .1);
}

.custom-markers {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: white;
    display: flex;
    background: red;
}

.stop-icon {
    width: 10px;
    height: 10px;
    border-radius: 1px;
    background: white;
}

.grid-90-10 {
    display: grid;
    grid-template-columns: 90% 10%;
}

.fontsize-2rem {
    font-size: 2rem;
}

.fontsize-1-6rem {
    font-size: 1.6rem;
}

.color-gray {
    color: lightgrey !important;
}

.custom-markers-red {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: white;
    display: flex;
    background: red;
}

.custom-markers-green {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: white;
    display: flex;
    background: green;
}

.custom-markers-blue {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: white;
    display: flex;
    background: blue;
}

.custom-markers-start {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: white;
    display: flex;
    background: green;
}

.custom-markers-end {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: white;
    display: flex;
    background: blue;
}

.custom-markers-destination {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: black;
    display: flex;
}

.color-green {
    color: green;
}

.color-red {
    color: red;
}

@media only screen and (max-width: 991px) {
    .users-icons i {
        font-size: 1rem;
    }

    .users-icons {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 1199px) {
    .mb-custom-5 {
        margin-bottom: 2rem !important;
    }
}

.hoverCustom:hover {
    cursor: pointer;
    color: #184f90;
}

.custom_header {
    padding-bottom: 10px;
    display: inline-block;
}

.close {

}

.app-menu {
    z-index: 1000;
    animation-name: chatSlideIn;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    transform: translateX(280px);
    background: var(--white);
}

.app-menu-wide {
    width: 400px;
    transform: translateX(400px);
}

@keyframes chatSlideIn {
    from {
        transform: translateX(280px);
    }
    to {
        transform: translateX(0);
    }
}

.anim-fade-in-full {
    animation-name: tableFadeIn;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

.chat-container-custom {
    top: 0;
    height: 100%;
    /*background: #F8F8F8;*/
    background: var(--light);
    position: fixed;
    width: 500px;
    left: -500px;
    padding-left: .75em;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.chat-input-container-custom {
    position: absolute;
    width: 100%;
    background-color: var(--input);
}

.chat-container-custom .chat-input-container {
    padding-left: 2em;
    padding-right: 2em;
}

.cursor-default:hover {
    cursor: default;
}

.close-position {
    top: 13px;
    right: 15px;
    position: absolute;
}

.scroll-chat {
    height: calc(100% - 170px) !important;
    width: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding-top: 15px;
}

.rounded .app-menu {
    border-radius: 0;
}

.conversation {
    border: 0 !important;
    padding: 8px !important;
    border-radius: 8px;
}

.conversation.active {
    color: var(--link);
    background-color: var(--light);
}

.scroll-overflow-y {
    overflow-y: auto;
}

.img-thumbnail-40 {
    width: 40px;
    height: 40px;
    background-color: var(--light);
}

.img-thumbnail-55 {
    width: 55px !important;
    height: 55px !important;
    background-color: var(--light);
}

.contact-hover:hover p {
    color: var(--link);
}

.count-badge {
    font-size: 9px;
    color: #00365a;
    border: 1px solid #00365a;
    border-radius: 10px;
    position: absolute;
    width: 18px;
    height: 15px;
    text-align: center;
    font-weight: 700;
    top: -4px;
    right: 2px;
    line-height: 14px;
}

.user-status {
    position: relative;
    padding-left: 1em;
}

.user-status::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #C8D0DA;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.user-status-online::after {
    background-color: #37B664;
}

.message-badge {
    position: absolute;
    right: 0;
    bottom: 15px;
}

.navbar #iconMenuDropdown {
    width: 330px;
    height: 300px;
}

/*Temp rule*/
.navbar .icon-menu-item span {
    padding-left: 0;
    padding-right: 0;
}

.icon-menu-item-btn {
    height: 65px;
    background: transparent;
    border: 0;
    vertical-align: top;
    position: relative;
}

.icon-menu-item-btn > * {
    position: relative;
    z-index: -1;
}

.icon-menu-item-btn:hover,
.icon-menu-item-btn:focus,
.icon-menu-item-btn:focus > svg {
    fill: #4e83a6;
    color: #4e83a6 !important;
}

.sub-menu-border-top {
    position: relative;
}

.sub-menu-border-top::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: var(--border);
}

.menu-sub-hidden .sub-menu-border-top {
    opacity: 0;
}

.custom-pointer {
    cursor: pointer;
}

.dropdown-menu-badge {
    color: var(--muted);
    border-radius: 50%;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 18px !important;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .5;
}

@media only screen and (max-height: 1000px) {
    .menu-icon {
        width: 35px;
    }

    .menu .main-menu ul li a {
        height: 60px;
    }
}

.dropdown-item {
    user-select: none;
}

.text-online {
    color: #37B664 !important;
    font-weight: 700;
}

.tooltip {
    top: -48px !important;
    left: 50% !important;
    transform: translateX(-50%);
    opacity: 1;
    display: none;
}

.gantt-bar:hover .tooltip {
    display: block;
}

.tooltip .arrow {
    bottom: -6px;
    left: 50% !important;
    transform: translateX(-50%) rotate(45deg);
    height: 12px;
    width: 12px;
    background-color: var(--white);
    border-right: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
}

.tooltip-inner {
    border-color: var(--border);
    background-color: var(--white);
    color: var(--primary);
    max-width: 100% !important;
}

.custom-tooltip {
    background: #00365a;
    color: #fff;
    width: fit-content;
    padding: 6px 16px;
    border-radius: 6px;
    position: absolute;
    right: 105px;
    cursor: pointer;
}

.custom-tooltip-arrow {
    background: #00365a;
    width: 7px;
    height: 7px;
    position: absolute;
    right: -3px;
    top: 12px;
    transform: rotate(45deg);
}

.custom-tooltip-top {
    background: #00365a;
    color: #fff;
    width: fit-content;
    padding: 6px 16px;
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: -35px;
    cursor: pointer;
}

.custom-tooltip-top-arrow {
    background: #00365a;
    width: 7px;
    height: 7px;
    position: absolute;
    right: 50%;
    bottom: -4px;
    transform: rotate(45deg);
}

.chat-height {
    height: calc(100% - 85px) !important;
}

.copy-to-clipboard {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 5px;
    border-radius: 5px;
    text-decoration: underline;
}

.copy-to-clipboard-dark {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    letter-spacing: 1px;
    color: #7e7e7e;
    background: #18191b;
    padding: 5px;
    border-radius: 5px;
    text-decoration: underline;
}

.word-break {
    word-break: break-word !important;
}

.field-border {
    border: 1px solid var(--border);
}

.remove-border {
    border: 0 !important;
}

.dialog-tabs {
    padding-top: 0;
    border-bottom: 2px solid var(--tableBorder) !important;
}

.dialog-tabs-margin {
    margin-left: 16px !important;
    margin-right: 16px;
}

.dialog-table-scroll {
    overflow: auto;
    padding-right: 1em;
    margin-left: -1em;
    background-color: var(--white);
    z-index: 1;
    position: relative;
}

.dialog-table-scroll.scrolled {
    border-top: 1px dashed var(--border);
}

/* Cron component override */
/*.cron-builder input,*/
/*.cron-builder select {*/
/*    margin-left: .6em;*/
/*    margin-right: .6em;*/
/*}*/

.cron-builder-bg {
    background-color: var(--link) !important;
}

.limit-field-select {
    width: 100px;
}

@media only screen and (min-width: 768px) {
    .limit-field-container > * {
        width: 90px;
        float: right;
    }
}

.limit-field-select > * > [class$="-control"] {
    padding-left: 4px !important;
}

.progress-bar-container {
    width: 800px;
    margin: 0 auto;
}

.progress-bar-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 0 3px;
    padding: 8px 10px 7px 30px;
    min-width: 180px;
    float: left;
    position: relative;
    background-color: #d9e3f7;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.comment-section {
    max-height: 25vh;
    overflow-y: scroll;
}

.comment-section::-webkit-scrollbar {
    display: none;
}

.comment-user-name {
    font-weight: bolder;
    margin-right: 5px;
}

.add-comment-section {

}

.comment-text-section {
    width: 100%;
    display: flex;
}

.flex-column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.comment-message {
    width: 95%;
}

.comment-controls {
    width: 5%;
    height: 100%;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid #d9e3f7;
    z-index: 2;
    transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}

.arrow-steps .step:first-child:before {
    border: none;
}

.arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
    position: relative;
}

.arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
    color: #fff;
    background-color: #23468c;
}

.arrow-steps .step.current:after {
    border-left: 17px solid #23468c;
}

@media only screen and (max-width: 1680px) {
    .arrow-steps .step {
        min-width: 110px;
    }

    .progress-bar-container {
        width: 525px;
        margin: 0 auto;
    }
}

/*.data-table-gantt {*/
/*    width: 330px;*/
/*}*/


/*.data-table-gantt thead {*/
/*    height: 60px*/
/*}*/

/*.data-table-gantt,*/
/*.data-table-gantt th,*/
/*.data-table-gantt td {*/
/*    border-left: 1px solid #F5F7F8;*/
/*    border-right: 1px solid #F5F7F8;*/
/*    text-align: center;*/
/*    text-overflow: ellipsis;*/
/*    overflow: hidden;*/
/*}*/

/*.data-table-gantt th {*/
/*    border-top: 1px solid #D9D9D9;*/
/*}*/

/*.data-table-gantt th {*/
/*    border-bottom: 2px solid #D9D9D9;*/
/*}*/

/*.ganttChartWrap {*/
/*    width: calc(100% - 330px)*/
/*}*/

.gantt-table {
    position: relative;
    width: 240px;
    border-left: 2px solid var(--border);
    border-top: 2px solid var(--border);
    border-bottom: 2px solid var(--border);
}

.gantt-table * {
    box-sizing: border-box;
}

.gantt-chart {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 2px solid var(--border);
    width: 100%;
}

.gantt-chart-scroll {
    overflow: hidden;
    width: calc(100% - 240px);
}

.gantt-columns {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    white-space: nowrap;
}

.gantt-chart-col {
    display: inline-block;
    height: 100%;
    width: 60px;
    border-right: 1px solid var(--border);
}

.gtable-date {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
    background-color: var(--white);
    border-bottom: 1px solid var(--border);
}

.gtable-date > * {
    position: relative;
}

.gtable-date:first-child {
    color: var(--white)
}

.gantt-table-col {
    width: 120px;
    display: inline-block;
    height: 100%;
    /*width: 60px;*/
    border-right: 1px solid var(--border);
}

.gantt-row {
    border-bottom: 1px solid var(--border);
    width: 100%;
    display: flex;
    align-items: center !important;
    box-sizing: border-box;
}

.gtable-row-heading {
    font-weight: 700;
}

.gantt-row-col {
    width: 120px;
    text-align: center;
    position: relative;
    z-index: 2;
}

.gantt-table-sub-row {
    text-align: center;
    width: 100%;
}


.gantt-table-sub-row:not(:first-child) {
    border-top: 1px solid var(--border);
}

.gtable-row-bars {
    display: flex;
    position: relative;
    user-select: none;
    white-space: nowrap;
    width: 100%;
}

.gantt-bar {
    min-height: 30px;
    background-color: #2495C5;
    position: absolute;
    cursor: pointer;
    border-radius: 20px;
    display: inline-block;
    transform: translateY(15px);
}

.gantt-bar:hover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.gantt-bar-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px 12px;
}

.gantt-bar-hover {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    border-left: 1px dashed var(--primary);
    border-right: 1px dashed var(--primary);
    background-color: rgba(0, 0, 0, .1);
}

.gantt-bar-body:hover + .gantt-bar-hover {
    display: block;
}

.bar-text {
    position: absolute;
    white-space: nowrap;
    color: var(--white);
}

.gantt-bar:hover {
    z-index: 10;
}

.bar-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
}

.gantt-bar:hover .bar-text-ellipsis {
    background-color: #2495C5;;
    top: 0;
    left: 0;
    text-overflow: initial;
    max-width: initial;
    padding: 4px 10px 6px;
    border: 1px solid var(--primary);
}

.bar-text-outside {
    left: 100%;
    padding-left: 1em;
}

.gantt-chart-marker {
    background-color: rgba(10, 52, 68, 0.13);
}

.gantt-chart-sub-row {
    box-sizing: border-box;
    width: 100%;
}

.gantt-chart-sub-row:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.gantt-date-row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--white);
    z-index: 1;
    user-select: none;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
    box-sizing: border-box;
}


.gantt-date-row:nth-child(odd) {
    background-color: var(--light);
}

.gantt-bar-alt {
    background-color: #b69329;
}

/* Gantt end */

/* Progress arrows*/
.progress-arrow {
    --height: 36px;
    width: 100%;
    margin-right: -16px;
    color: #fff;
    position: relative;
    height: var(--height);
    overflow: hidden;
}

.progress-arrow-body {
    background-color: var(--icon);
    z-index: 10;
    padding-right: 22px;
    height: var(--height);
}

.progress-arrow:last-child {
    overflow: hidden;
    width: calc(100% - 16px);
    margin-right: 0;
}

.progress-arrow:last-child .progress-arrow-body::after {
    display: none !important;
}

.progress-arrow:last-child .progress-arrow-body {
    /*margin-right: var(--height);*/
    /*padding-left: calc(var(--height) / 2);*/
    /*padding-right: 0;*/
    overflow: hidden;
    padding-right: 0;

}

.progress-arrow-body::after {
    content: "";
    position: absolute;
    height: 34px;
    width: 34px;
    background-color: var(--icon);
    right: 6px;
    top: 2px;
    transform: rotate(45deg);
    border-right: 1px solid var(--white);
    border-top: 1px solid var(--white);
    z-index: 2;
}

.progress-arrow-body::before {
    content: "";
    position: absolute;
    height: var(--height);
    width: 10px;
    background-color: var(--icon);
    left: 0;
    top: 0;
    z-index: 0;
}

.progress-arrow:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.progress-arrow-current {
    background-color: var(--link);
}

.progress-arrow-current.progress-arrow-dispatched,
.progress-arrow-current.progress-arrow-dispatched::before,
.progress-arrow-current.progress-arrow-dispatched::after {
    background-color: #3730a3 !important;
    color: #e0e7ff
}

.progress-arrow-canceled,
.progress-arrow-canceled::before,
.progress-arrow-canceled::after {
    background-color: #FEE2E2 !important;
    color: var(--danger)
}

.progress-arrow-delivered,
.progress-arrow-delivered::before,
.progress-arrow-delivered::after {
    background-color: #d1fae5 !important;
    color: #065f46
}

.progress-arrow-current:before,
.progress-arrow-current:after {
    background-color: var(--link);
}

.progress-arrow-text {
    margin: 0;
    line-height: var(--height);
}

.grid-hover:hover {
    background-color: var(--link);
    color: var(--white)
}

/* Dashboard builder */
.builder-row {
    height: 80px;
}

.builder-row {
    border: 2px solid transparent;
}

.builder-widget {
    color: var(--primary) !important;
    fill: var(--primary) !important;
    cursor: grab;
}

.builder-widget svg {
    opacity: .65;
}

.builder-row-active {
    border: 2px solid var(--button);
}

.builder-widget-unused:hover,
.builder-row-active .builder-widget:hover {
    background-color: var(--link);
    color: #fff !important;
    fill: #fff !important;
    cursor: grab;
}

.builder-widget-unused {
    width: calc(50% - 8px) !important;
    height: 80px;
    margin-bottom: 16px;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.widget-close-position {
    position: absolute;
    right: 5px !important;
    top: 5px !important;
}

.widget-active {
    z-index: 101;
    position: relative;
}

/*.position-right {*/
/*    background-color: #f18017;*/
/*}*/

.widget-drop-right {
    top: -2px;
    right: 2px;
    margin: .5em;
    border-radius: 12px;
    height: calc(100% - 1em) !important;
    width: calc(48% - 1em) !important;
    border: 1px dashed var(--muted);
}

.widget-drop-left {
    top: -2px;
    left: 2px;
    margin: .5em;
    border-radius: 12px;
    height: calc(100% - 1em) !important;
    width: calc(48% - 1em) !important;
    border: 1px dashed var(--muted);
}

.widget-transition-all {
    transition: .6s all;
}

.widget-translate-x-100 {
    transform: translateX(calc(-100% - 16px));
}

.widget-translate-x-50 {
    transform: translateX(calc(100%));
    width: calc(50% + 8px) !important;
}

.widget-translate-x-100-negative {
    transform: translateX(calc(100% + 16px));
}

.widget-translate-x-50-negative {
    transform: translateX(0);
    width: calc(50% - 8px) !important;
}

.dropdown-reverse div[class$="-menu"] {
    transform: translateY(calc(-100% - 54px));
}

/* Table progress */
.table-progress {
    overflow: hidden;
}

.table-progress-arrow {
    position: relative;
    flex: 0.15;
    transition: flex 1s;
    background-color: var(--muted);
    color: #fff;
    height: 35px;
    line-height: 35px;
    padding-left: 2em;
    box-sizing: border-box;
}

.table-progress-arrow:hover {
    flex: 1
}

.table-progress-arrow::after {
    content: "";
    position: absolute;
    background-color: var(--muted);
    height: 36px;
    width: 36px;
    right: -18px;
    top: 0;
    transform: rotate(45deg);
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    z-index: 1;
}

.table-progress-arrow.table-arrow-current {
    flex: 3;
    background-color: var(--link);
}

.table-progress-arrow.table-arrow-current::after {
    background-color: var(--link);
}

.table-progress-arrow:hover,
.table-progress-arrow:hover::after {
    background-color: var(--primary);
}

.table-progress .table-arrow-text {
    margin: 0;
    line-height: 36px;
}

.table-progress .table-arrow-text {
    display: none;
    text-align: center;
}

.table-progress .table-arrow-current .table-arrow-text {
    display: block;
}

.table-progress:hover .table-progress-arrow {
    flex: 0.02;
}

.table-progress .table-progress-arrow:last-child:not(.table-arrow-current) {
    flex: 0.2;
}

.table-progress:hover .table-progress-arrow:hover {
    flex: 1;
}

.table-progress:hover .table-arrow-current .table-arrow-text {
    display: none;
}

.table-progress:hover .table-progress-arrow:hover .table-arrow-text {
    display: block;
}

.doughnut-chart-legend {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -85%);
    text-align: center;
    font-size: 14px;
    display: block;
}

.doughnut-chart-legend-percent {
    margin-top: .25em;
    margin-bottom: .25em;
    display: block;
    font-size: 36px;
}

.input-group-icon svg {
    fill: var(--link)
}

.btn:hover .input-group-icon svg {
    fill: var(--white) !important;
}

.input-group-icon > div {
    background-color: var(--link);
}

.btn:hover .input-group-icon > div {
    background-color: var(--white) !important;
}

.progress-bar-text-banner {
    color: #fff !important;
    font-size: 1.7rem;
    width: 110px;
    font-weight: 300;
}

.star {
    position: relative;
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: top;
}

.star::after {
    content: ' \2606';
    font-size: 2em;
    color: #ffc107;
    position: absolute;
    top: -6px;
    left: 2px;
}

.star-active::after {
    content: ' \2605';
}

.widget-title {
    padding-left: 13px;
    position: relative;
}

.widget-title i {
    position: absolute;
    left: -1em;
    padding: .4em;
}

.widget-title:hover i {
    display: inline-block !important;
}

.ticket-img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.ticket-show {
    display: none;
}

@media only screen and (max-width: 767px) {
    .ticket-hide {
        display: none;
    }

    .ticket-show {
        display: block;
    }
}

.hover-underline:hover {
    text-decoration: underline;
}

.comment-like-comment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.comment-like-comment i {
    font-size: 20px;
    margin-right: 10px;
    transition: .2s ease;
}

.color-blue {
    color: #184f90;
}

.hover-red:hover {
    color: red;
}

.hover-blue {
    transition: .2s ease;
}

.hover-blue:hover {
    color: #184f90 !important;
}

.number-of-likes {
    position: absolute;
    right: 23px;
    top: -10px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 4px black;
    font-size: 10px !important;
}

@media all and (display-mode: fullscreen) {
    .fullscreen {
        width: 100vw;
        height: 100vh;
        margin: 0;
    }
}

.contact-badge {
    box-sizing: border-box;
    height: 40px;
    line-height: 24px;
    border-radius: 50px;
    outline: initial !important;
    box-shadow: none !important;
    font-size: 0.8rem;
    padding: 0.5rem 2.5rem 0.5rem 3.25rem;
    background-color: var(--light);
    position: relative;
}

.contact-badge-avatar {
    border-radius: 50px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--border);
}

.contact-badge-avatar img {
    max-width: 100%;
    min-height: 40px;
}

.contact-badge-remove {
    border: 0;
    position: absolute;
    right: .5em;
    background-color: transparent;
}

.contact-badge-empty {
    padding: 0.5rem 2.5rem 0.5rem 1.75rem;
}

.danger-colors {
    background-color: #FEE2E2;
    color: #B91C1C
}

.form-control.is-invalid {
    border-color: #B91C1C !important;
}

/* Utility classes */
.bg-button {
    background-color: var(--button) !important;
}

.bg-purple {
    background-color: #96539c;
}

.bg-button-i {
    background-color: var(--button) !important;
}

.bg-primary {
    background-color: var(--link);
}

.pseudo-bg-inherit::before,
.pseudo-bg-inherit::after {
    background-color: inherit;
}

.text-white {
    color: var(--white);
}

.icon-white i {
    color: var(--white) !important;
}

.bg-white {
    background: var(--white) !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-border {
    background-color: var(--border);
}

.bg-table-border {
    background-color: var(--tableBorder);
}

.cursor-garb {
    cursor: grab;
}

.checkbox-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border: 2px solid transparent;
    cursor: pointer;
}

.active-checkbox-card {
    border: 2px solid #4e83a6;
}

.checkbox-card i {
    padding: 20px;
    font-size: 60px;
}

.checkbox-card span {
    padding-bottom: 20px;
}

.form-control-bare:not(:focus):not(:disabled) {
    background: transparent !important;
    border-color: transparent !important;
}


.location-row-height {
    height: 41px;
}

.drag-area,
.location-drag {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--primary);
    cursor: grab;
}

.location-drag i {
    cursor: grab;
}


.location-drag-dropzone td {
    opacity: .5;
}

.location-btn {
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid var(--primary);
    font-size: 18px;
    z-index: 1;
}

.location-btn-minus {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.location-btn-plus {
    position: absolute;
    bottom: -26px;
    background-color: var(--link);
    color: var(--white);
}

.location-btn-plus-first {
    position: absolute;
    top: -26px;
    left: 50%;
    transform: translateX(-50%);
}

.location-btn:focus {
    background-color: var(--primary);
    color: var(--white);
}

.popover .popover-body {
    color: var(--primary);
    min-width: 245px;
}

.popover-info-tooltip {
    top: 25px !important;
    border-radius: 10px;
    border-color: var(--border);
    border-top: 3px solid var(--link);
    background-color: var(--white);
    /*opacity is changed to 1 with JS to prevent jittering*/
    opacity: 0;
}

.popover-info-tooltip table td {
    word-break: keep-all;
    white-space: nowrap;
}

.popover-info-tooltip > .tooltip-body {
    width: 100%;
}

.btn-info-tooltip,
.btn-location-tooltip {
    border: 0;
    background: transparent;
    color: var(--primary)
}

.btn-info-tooltip:hover > i,
.btn-info-tooltip:focus > i {
    color: var(--link);
}

.btn-location-tooltip:hover,
.btn-location-tooltip:focus {
    background-color: var(--border);
    border-radius: 30px;
}

.popover {
    max-width: max-content !important;
}

.popover-body th,
.popover-body td {
    background: transparent !important;
}

.table-striped .popover-body thead tr,
.table-striped .popover-body tbody tr {
    background: transparent !important;
}

.popover-body th:first-child,
.popover-body td:first-child {
    padding-left: 0 !important;
}

.popover-body th:last-child,
.popover-body td:last-child {
    padding-right: 0 !important;
}

.modal-footer-inside-body {
    margin-left: -16px;
    margin-right: -16px;
}

/* Theme override */
body {
    line-height: 1.3;
}

.modal .modal-header, .modal .modal-body, .modal .modal-footer {
    padding: 1rem 1rem;
}

.modal-open .card .card-body {
    padding: 1rem;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border-color: var(--border) !important;
}

/*input[type="radio"], input[type="checkbox"] {*/
/*    background-color: var(--inputDarker);*/
/*}*/

/*.form-control.is-invalid {*/
/*    padding-right: 12px !important;*/
/*}*/

/* When there are two dialog open */
.modal-open + .modal-open > .modal {
    z-index: 1160;
}

.modal-backdrop {
    z-index: 1040;
}

.modal-open + .modal-open + .modal-backdrop {
    z-index: 1145;
}

.minimized-window {
    position: fixed;
    z-index: 20;
    height: 30px;
    background: #fff;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, .2);
    border: 1px solid #d7d7d7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px;
    padding-right: 0px;
    cursor: pointer;
}

.minimized-window .name {
    padding-right: 20px;
    color: gray;
    font-weight: bolder;
}

.hover-bg-red {
    transition: .2s ease;
    height: 30px;
    width: 30px;
}

.hover-bg-red:hover {
    background: red;
}

.hover-bg-gray {
    transition: .2s ease;
    height: 30px;
    width: 30px;
}

.hover-bg-gray:hover {
    background: gray;
}

.circle-icon {
    width: 18px;
    height: 18px;
    text-align: center;
    background: var(--link);
    color: var(--white);
    display: inline-block;
    border-radius: 18px;
    vertical-align: top;
}

.border-red {
    border: 1px solid #B91C1C;
}

.form-control-time {
    width: 100px;
}

.table-working-hours td {
    padding-left: 1em;
    padding-right: 1em;
}

.rating-icons {
    display: flex;
}

.field-error-text-creatable {
    position: absolute;
    right: 35px;
    top: 15px;
}

/*Small Grid Gutter*/
.g-sm > div[class^="col"] {
    padding-left: 4px;
    padding-right: 4px;
}


.score-border {
    padding: 1em 1em 0 1em;
    border: 2px solid var(--border);
    border-radius: 15px;
}

.theme-rounded {
    border-radius: .75em;
}

.disabled-date {
    pointer-events: none;
    border-radius: 5px;
    color: #ccc;
}

.height-fix {
    height: auto !important;
}

.darker-section {
    background-color: var(--tableBorder);
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 16px;
}

/* Location table */
.location-dragged {
    background-color: rgba(0, 54, 90, 0.1) !important
}

.location-stop-input-height {
    min-height: 42px;
}

.location-stop-input-height > div {
    min-height: 40px;
}

.location-br-0 {
    border-right: 0;
    border-radius: 0;
}

.location-bl-0 {
    border-left: 0;
    border-radius: 0;
}

.location-row {
    width: 100%;
    border-bottom: 2px solid var(--light);
}

.location-stop-date-header {
    width: 200px;
}

.location-grab-area {
    width: 30px;
}

.location-info {
    width: 75px;
}

.location-stop {
    width: 300px;
    flex: 1;
}

.location-stop-type {
    width: 170px;
}

.location-reference-number {
    width: 180px;
}

/* location-stop + location-stop-type are inside */
.location-stop-wrapper {
    display: flex;
    flex: 1;
}

.location-stop-date {
    width: 100px;
}

.location-stop-time {
    width: 85px;
}

.location-date-wrapper {
    width: 402px;
    display: flex;
}

.location-stop-ref-no {
    width: 130px;
}

.location-appointment {
    min-width: 120px;
    text-align: center;
}

.location-appointment .custom-control-label {
    line-height: 1.2;
}

/*location-stop-ref-no + location-appointment inside*/
.location-reference-wrapper,
.location-stop-type-wrapper {
    display: flex;
    width: 345px;
    min-width: 345px;
}

.location-add-remove {
    min-width: 40px;
}

.location-label-hide {
    display: none;
}

@media (max-width: 1600px) {
    .location-stop,
    .location-reference-number {
        width: 100%;
    }

    .location-stop,
    .location-reference-number,
    .location-margin-remove {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .location-stop-wrapper,
    .location-date-wrapper,
    .location-reference-wrapper,
    .location-stop-type-wrapper {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
        min-width: auto;
    }

    .location-stop-wrapper > div:first-child,
    .location-date-wrapper-margin {
        margin-bottom: 16px;
    }

    .location-date-wrapper {
        width: 185px;
    }

    .location-reference-wrapper,
    .location-stop-type-wrapper {
        width: 185px;
    }

    .location-header-visible {
        display: none !important;
    }

    .location-label-hide {
        display: block !important;
    }

    .location-appointment {
        margin-top: 16px;
    }

    .location-row:nth-child(3) {
        border-top: 2px solid var(--light);
    }

    .location-mt-3 {
        margin-top: 16px
    }
}

/*---------------- CUSTOM LEAFLET MARKER ----------------------*/
.marker-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
}

.marker-pin-content {
    transform: rotate(45deg);
    width: 70%;
    height: 70%;
    background: #fff;
    border-radius: 50%;
    margin-right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
}

/* Invoice drag and drop table*/
.invoice-table-rounded > div:nth-child(2) {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.invoice-table-rounded > div:last-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.invoice-textarea-height {
    min-height: 80px;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.min-width-1200 {
    min-width: 1200px;
}

.erase-border td {
    border: none !important;
}

.vertical-align-top {
    vertical-align: top;
}

.cursor-alias {
    cursor: alias !important;
}

.invoice-sub-sec-title {
    font-weight: bold;
    padding-left: 16px;
    font-size: 16px;
    margin: 8px 0;
}

.m-10-auto {
    margin: 10px auto;
}

.next-row-top-border + tr > td {
    border-top: 1px solid var(--tableBorder);
}

.remove-row-borders td {
    border: none !important;
}

.sticky-footer-buttons-align {
    text-align: right;
}

.custom-dropdown-menu {
    top: 100%;
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 11;
    box-sizing: border-box;
    background: var(--input);
    color: var(--primary);
    overflow-y: auto;
    max-height: 180px;
    min-width: 100px;
}

.custom-dropdown-option {
    color: var(--primary);
    cursor: pointer;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    background: var(--white);
}

.custom-dropdown-option:hover,
.custom-dropdown-option-selected {
    color: #fff;
    background: var(--button);
}

.custom-dropdown-option-active {
    color: var(--primary);
    background: var(--tableBorder);
}

.custom-dropdown-popup {
    position: absolute;
    bottom: calc(100% + 8px);
    left: 5px;
    padding: .2em .5em;
    background: var(--white);
    border: 1px solid var(--border);
}

.custom-dropdown-popup::after {
    content: "";
    position: absolute;
    background: var(--white);
    border-bottom: 1px solid var(--border);
    border-right: 1px solid var(--border);
    width: 8px;
    height: 8px;
    bottom: -4px;
    left: 5px;
    transform: rotate(45deg);
}

.help-sidebar {
    width: 30vw !important;
}

.content-html {
    height: 70vh;
    overflow-y: scroll;
}

.slideOutAnimationRight {
    z-index: 1000;
    animation-name: chatSlideOut;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    transform: translateX(0);
    background: var(--white);
}

@keyframes chatSlideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(30vw);
    }
}

.h-80-percent {
    height: 80% !important;
}

.edit-help-sidebar-section {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 40px;
}

.sidebar-textarea {
    height: 70%;
}

@media only screen and (max-height: 800px) {
    .sidebar-textarea {
        height: 50%;
    }

    .content-html {
        height: 50vh;
    }
}

.multilevel-menu i {
    padding-right: 10px !important;
}

.multilevel-menu li {
    list-style: none;
}

.multilevel-menu li .parent {
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    display: block;
    padding: 10px;
}

.multilevel-menu li .child {
    text-align: left;
    color: #848484;
    font-size: 14px;
    padding: 10px;
    display: block;
    white-space: nowrap;
}

.multilevel-menu li .parent:hover,
.multilevel-menu li .child:hover {
    background: lightgray;
}

.font-bold {
    font-weight: bold;
}

.font-12px {
    font-size: 12px;
}

.mt-2 {
    margin-top: 2rem
}

.stop-location-info-wrapper {
    width: 100%;
    display: flex;
}

@media (max-width: 1024px) {
    .stop-location-info-wrapper {
        flex-direction: column;
    }

    .location-stop-type-wrapper,
    .location-stop-type {
        width: 100%;
    }

    .location-stop-type {
        margin-left: 0;
        margin-top: 15px;
    }

    .location-date-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.profile-img-container {
    max-height: 56px;
    width: 56px;
}

.profile-img-container > img {
    margin: 0 !important;
    object-fit: cover;
}

.red-border {
    border: 1px solid red;
}

.excel-to-html-table body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
}

.excel-to-html-table table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}

.excel-to-html-table table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.excel-to-html-table table tr {
    border: 1px solid #ddd;
    padding: .35em;
}

.excel-to-html-table table tr:nth-child(even) {
    background: #f8f8f8;
}

.excel-to-html-table table th,
.excel-to-html-table table td {
    padding: .625em;
    text-align: left;
}

.excel-to-html-table table th {
    background: #999;
    color: #fff;
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.excel-to-html-table table td {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-size-cover {
    background-size: cover !important;
}

.actions-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    padding: 10px;
    border-radius: 20px;
    will-change: transform;
    top: 0;
    left: 0;
    transform: translate3d(-22px, -40px, 0px);
    box-shadow: 0 1px 15px rgba(0, 0, 0, .1), 0 1px 6px rgba(0, 0, 0, .1);
}

.actions-menu span {
    margin-bottom: 10px;
}


/* Actions */
/* if there are actions */
/*.simple-sticky-table table thead th:last-child,*/
/*.simple-sticky-table table tbody td:last-child {*/
/*    width: 130px;*/
/*}*/

/*.simple-table-actions {*/
/*    width: 130px;*/
/*}*/

.too-much-actions .table-action {
    display: none !important;
}

.too-much-actions .table-action:nth-child(-n+2) {
    display: inline-block !important;
}

.hide-actions .table-action {
    display: none !important;
}

.hide-first-two-actions button {
    display: none;
}

.hide-first-two-actions button:not(:nth-of-type(-n+2)) {
    display: inline-block;
}

.more-actions-pointer {
    position: absolute;
    right: 1em;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-top: 10px solid var(--white);
    bottom: -10px;
}

.action-menu {
    bottom: 4.2em;
    right: 13px;
}

.animate-pulse {
    animation: opacityPulse 2s infinite ease-in;
}

@keyframes opacityPulse {
    0% {
        opacity: .3
    }
    50% {
        opacity: .8
    }
    100% {
        opacity: .3
    }
}

.skeleton-data {
    height: 12px;
    width: 100%;
    background: #8a9fb4;
    border-radius: 5px;
}

.table-sort:hover .sort-icon {
    opacity: 100;
}

.sticky-actions {
    border-top: 1px solid var(--tableBorder);
}

.sticky-actions-border {
    border-left: 1px dashed var(--border);
}

/* Simple resource table END */

/* Mobile Table */
.mobile-table {
    border: 1px solid var(--border);
}

.mobile-table th,
.mobile-table td {
    border-bottom: 1px solid var(--border);
    padding: 1em;
    max-width: 200px;
}

.mobile-table th {
    width: 120px;
    min-width: 120px;
    background: var(--light);
    border-right: 1px solid var(--border);
}

@media only screen and (max-width: 767px) {
    .hide-card-sm {
        box-shadow: none;
        background: transparent;
        overflow: visible;
    }

    .wrap-sm {
        white-space: normal !important;
    }

    .remove-p-sm {
        padding: 0 !important;
    }
}

/* Mobile table end */

/* Ping animation */
@keyframes ping {
    from {
        transform: scale(.6);
        opacity: .5;
    }
    to {
        transform: scale(1);
        opacity: 0;
    }
}

.ping-ring {
    display: block;
    float: left;
    position: absolute;
    top: -3px;
    left: -3px;
    border-radius: 50%;
    padding: 1em;
    transform: scale(.6);
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    transform-origin: center;
    animation-name: ping;
    animation-duration: 2s;
    z-index: 100;
    border: 2px solid var(--button);
}

.ping-ring-button:hover .ping-ring {
    visibility: hidden;
}

.table-settings-row td {
    border: 1px solid var(--border);
}

.table-settings-row td:first-child {
    border-right: 0;
}

.table-settings-row td:nth-child(2) {
    border-left: 0;
    border-right: 0;
}

.table-settings-row td:last-child {
    border-left: 0;
}

.btn-drag:hover {
    background: var(--tableBorder);
}

.header-mobile-btn {
    height: 37px;
    width: 37px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.table-overlay {

}

.legend {
    position: absolute;
    top: -8px;
    left: 8px;
    z-index: 1;
    padding: 0 3px;
}

.avatar-preview {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
    border: 1px solid var(--border);
    background-color: var(--tableBorder);
}

.avatar-preview img {
    width: 100%;
    height: 100%;
}

.avatar-remove {
    top: -10px;
    right: -6px;
}

.disable-hover:hover {
    cursor: default;
}

.registration-complete {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.registration-complete-icon {
    width: 100px;
    height: 100px;
    fill: green;
    border-radius: 50%;
    margin: auto;
}

/*.task-info {*/
/*    background: #d8efff;*/
/*    color: #00365a;*/
/*    display: inline-block;*/
/*    border-left: 3px solid #00365a;*/
/*}*/

.border-radius-0 {
    border-radius: 0 !important;
}

.box-shadow-10 {
    box-shadow: 0 1px 15px rgba(0, 0, 0, .1), 0 1px 6px rgba(0, 0, 0, .1);
}

.bg-gray {
    background-color: #e6e6e6;
}

.task-info {
    border: 1px solid var(--link);
    background: #d8efff;
}

.task-info-icon {
    background: var(--link);
    color: #fff;
    height: 100%;
}

.task-info svg {
    width: 22px;
    height: 22px;
    color: #fff
}

.task-info-danger {
    background: #ffe4e6;
    border-color: #b91c1c;
}

.task-info-danger-icon {
    background: #ef4444;
}

/*.test {*/
/*    background: linear-gradient(90deg, var(--inverseRGBTransparent) 0%, var(--inverseRGB) 90%);*/
/*}*/

.app-logo {
    width: 48px;
    height: 48px;
}

.board-column {
    background-color: #f4f5f7;
    padding: 10px;
    margin: 5px;
}

.cursor-move {
    cursor: move !important;
}

.filter-icon {
    padding: 11px 20px;
    border-radius: 6px;
}

.dropdown-filter .dropdown {
    position: absolute;
    min-width: 300px;
    right: 0;
    top: 50px;
    z-index: 3;
    background: #fff;
}

.text-stroke-thick {
    -webkit-text-stroke: thick;
}

@media (min-width: 576px) {
    .filter-dropdown {
        min-width: 480px;
    }
}

.color-orange {
    color: #fc991f !important;
}

.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #191914;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: #2093ed;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: #2093ed;
}

.spinner .bounce3 {
    background-color: #2093ed;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.photo-viewer-container img {
    max-width: 100%;
}

.color-toggle-animation {
    animation: color-toggle-animation 1s infinite ease-in;
}

@keyframes color-toggle-animation {
    0% {
        background: #fff;
    }
    50% {
        background: #443ea9;
        color: #fff
    }
    100% {
        background: #fff;
    }
}

svg > g > g:last-child {
    pointer-events: none
}

.bgdeco {
    margin: 5px;
    background: linear-gradient(to right, #ece8fd, #d0f9e4) fixed top center;
}

/*custom-sidebar*/
.sidebar-is-open {
    /*No transition*/
    margin-right: 540px;
}

/*63 footer height*/
.custom-sidebar {
    width: 500px;
    height: calc(100vh - 100px);
    padding-bottom: 63px;
    position: fixed;
    top: 100px;
    right: 0;
    background-color: var(--white);
    transform: translateX(500px);
}

.custom-sidebar-tab {
    height: calc(100vh - 241px);
}

@media (max-width: 1439px) {
    .custom-sidebar {
        top: 90px;
        height: calc(100vh - 90px);
    }

    .custom-sidebar-tab {
        height: calc(100vh - 244px);
    }
}

.custom-sidebar .timeline--wrapper {
    width: 100%;
    padding: 0 1rem 0 1rem;
}

.custom-sidebar .timeline-item-dateinner {
    font-size: 12px;
}

.custom-sidebar .timeline .entry .title {
    width: 50%;
}

.custom-sidebar .timeline .entry .body {
    width: 50%;
}

.custom-sidebar .timeline::before {
    left: 50%;
    transform: translateX(-50%);
}

.custom-sidebar button {
    border: 0
}

.chat-box {
    height: calc(100vh - 420px)
}

footer.page-footer {
    background-color: var(--white);
    padding-top: 20px;
    padding-bottom: 0;
    height: 63px;
}

.nav-item,
.nav-item > button {
    text-transform: uppercase;
}

/*.line-before {*/
/*    transition: .4s ease;*/
/*}*/

/*.line-before:before {*/
/*    border-top: 1px solid #606976;*/
/*    width: 30px;*/
/*    content: "";*/
/*    margin-right: 10px;*/
/*    transition: .4s ease;*/
/*    margin-left: 10px;*/
/*}*/

/*.font-bold.line-before:before {*/
/*    border-top: 2px solid #606976;*/
/*}*/

/*.line-before:hover:before {*/
/*    transition: .4s ease;*/
/*    width: 40px;*/
/*}*/

.table-grid-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 15px;
    padding: 15px;
}

.table-grid-view div table {
    height: 100%;
}

.table-grid-view div:last-child {
    grid-column: 1 / -1;
}

.menu-toggle-open {
    position: absolute;
    top: 5px;
    right: -17px;
}

.menu-toggle-button {
    position: absolute;
    top: 5px;
    right: -24px;
}

.main-menu-open {
    width: 13rem;
    transition: .5s ease;
}

.main-menu-closed {
    width: 1rem;
    transition: .5s ease;
}

.main-menu-closed:hover {
    width: 13rem;
}

.main-sub-menu-open {
    transition: .5s ease;
    opacity: 1;
}

.main-sub-menu-closed {
    transition: .5s ease;
    opacity: 0;
}

.main-sub-menu-closed:hover {
    opacity: 1;
}

.image-loading-spin-animation {
    width: 40px;
    height: 40px;
    border: dotted 5px rgba(111, 202, 220, .75);
    border-radius: 100%;
    animation: imageLoadingSpinAnimation 1s linear infinite;
}

@keyframes imageLoadingSpinAnimation {
    0% {
        transform: rotate(0deg) scale(0.8);
        border-top-color: transparent;
        border-right-color: transparent;
    }
    50% {
        transform: rotate(180deg) scale(1.2);
        border-color: rgba(225, 20, 98, .75);
        border-top-color: transparent;
        border-right-color: transparent;
    }
    100% {
        transform: rotate(360deg) scale(0.8);
        border-color: rgba(233, 169, 32, .75);
        border-top-color: transparent;
        border-right-color: transparent;
    }
}

@media print {
    @page {
        size: A3;
    }
}
