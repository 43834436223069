@font-face {
    font-family: 'iconsminds';
    src: url('../font/iconsminds.eot?42207379');
    src: url('../font/iconsminds.eot?42207379#iefix') format('embedded-opentype'),
    url('../font/iconsminds.woff2?42207379') format('woff2'),
    url('../font/iconsminds.woff?42207379') format('woff'),
    url('../font/iconsminds.ttf?42207379') format('truetype'),
    url('../font/iconsminds.svg?42207379#iconsminds') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'iconsminds';
    src: url('../font/iconsminds.svg?42207379#iconsminds') format('svg');
  }
}
*/

[class^="iconsminds-"]:before, [class*=" iconsminds-"]:before {
    font-family: "iconsminds";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.iconsminds-add-space-after-paragraph:before {
    content: '\e800';
}

/* '' */
.iconsminds-add-space-before-paragraph:before {
    content: '\e801';
}

/* '' */
.iconsminds-align-center:before {
    content: '\e802';
}

/* '' */
.iconsminds-align-justify-all:before {
    content: '\e803';
}

/* '' */
.iconsminds-align-justify-center:before {
    content: '\e804';
}

/* '' */
.iconsminds-align-justify-left:before {
    content: '\e805';
}

/* '' */
.iconsminds-align-justify-right:before {
    content: '\e806';
}

/* '' */
.iconsminds-align-left:before {
    content: '\e807';
}

/* '' */
.iconsminds-align-right:before {
    content: '\e808';
}

/* '' */
.iconsminds-decrase-inedit:before {
    content: '\e809';
}

/* '' */
.iconsminds-increase-inedit:before {
    content: '\e80a';
}

/* '' */
.iconsminds-indent-first-line:before {
    content: '\e80b';
}

/* '' */
.iconsminds-indent-left-margin:before {
    content: '\e80c';
}

/* '' */
.iconsminds-indent-right-margin:before {
    content: '\e80d';
}

/* '' */
.iconsminds-line-spacing:before {
    content: '\e80e';
}

/* '' */
.iconsminds-arrow-fork:before {
    content: '\e80f';
}

/* '' */
.iconsminds-arrow-from:before {
    content: '\e810';
}

/* '' */
.iconsminds-arrow-inside-45:before {
    content: '\e811';
}

/* '' */
.iconsminds-arrow-inside-gap-45:before {
    content: '\e812';
}

/* '' */
.iconsminds-arrow-inside-gap:before {
    content: '\e813';
}

/* '' */
.iconsminds-arrow-inside:before {
    content: '\e814';
}

/* '' */
.iconsminds-arrow-into:before {
    content: '\e815';
}

/* '' */
.iconsminds-arrow-junction:before {
    content: '\e817';
}

/* '' */
.iconsminds-arrow-loop:before {
    content: '\e818';
}

/* '' */
.iconsminds-arrow-merge:before {
    content: '\e819';
}

/* '' */
.iconsminds-arrow-mix:before {
    content: '\e81a';
}

/* '' */
.iconsminds-arrow-out-left:before {
    content: '\e81b';
}

/* '' */
.iconsminds-arrow-out-right:before {
    content: '\e81c';
}

/* '' */
.iconsminds-arrow-outside-45:before {
    content: '\e81d';
}

/* '' */
.iconsminds-arrow-outside-gap-45:before {
    content: '\e81e';
}

/* '' */
.iconsminds-arrow-outside-gap:before {
    content: '\e81f';
}

/* '' */
.iconsminds-arrow-outside:before {
    content: '\e820';
}

/* '' */
.iconsminds-arrow-over:before {
    content: '\e821';
}

/* '' */
.iconsminds-arrow-shuffle:before {
    content: '\e822';
}

/* '' */
.iconsminds-arrow-squiggly:before {
    content: '\e823';
}

/* '' */
.iconsminds-arrow-through:before {
    content: '\e824';
}

/* '' */
.iconsminds-arrow-to:before {
    content: '\e825';
}

/* '' */
.iconsminds-double-circle:before {
    content: '\e826';
}

/* '' */
.iconsminds-full-view-2:before {
    content: '\e827';
}

/* '' */
.iconsminds-full-view:before {
    content: '\e828';
}

/* '' */
.iconsminds-maximize:before {
    content: '\e829';
}

/* '' */
.iconsminds-minimize:before {
    content: '\e82a';
}

/* '' */
.iconsminds-resize:before {
    content: '\e82b';
}

/* '' */
.iconsminds-three-arrow-fork:before {
    content: '\e82c';
}

/* '' */
.iconsminds-view-height:before {
    content: '\e82d';
}

/* '' */
.iconsminds-view-width:before {
    content: '\e82e';
}

/* '' */
.iconsminds-arrow-around:before {
    content: '\e82f';
}

/* '' */
.iconsminds-arrow-barrier:before {
    content: '\e830';
}

/* '' */
.iconsminds-arrow-circle:before {
    content: '\e831';
}

/* '' */
.iconsminds-arrow-cross:before {
    content: '\e832';
}

/* '' */
.iconsminds-arrow-back-2:before {
    content: '\e833';
}

/* '' */
.iconsminds-arrow-back-3:before {
    content: '\e834';
}

/* '' */
.iconsminds-arrow-back:before {
    content: '\e835';
}

/* '' */
.iconsminds-arrow-down-2:before {
    content: '\e836';
}

/* '' */
.iconsminds-arrow-down-3:before {
    content: '\e837';
}

/* '' */
.iconsminds-arrow-down-in-circle:before {
    content: '\e838';
}

/* '' */
.iconsminds-arrow-down:before {
    content: '\e839';
}

/* '' */
.iconsminds-arrow-forward-2:before {
    content: '\e83a';
}

/* '' */
.iconsminds-arrow-forward:before {
    content: '\e83b';
}

/* '' */
.iconsminds-arrow-left-2:before {
    content: '\e83c';
}

/* '' */
.iconsminds-arrow-left-in-circle:before {
    content: '\e83d';
}

/* '' */
.iconsminds-arrow-left:before {
    content: '\e83e';
}

/* '' */
.iconsminds-arrow-next:before {
    content: '\e83f';
}

/* '' */
.iconsminds-arrow-refresh-2:before {
    content: '\e840';
}

/* '' */
.iconsminds-arrow-refresh:before {
    content: '\e841';
}

/* '' */
.iconsminds-arrow-right-2:before {
    content: '\e842';
}

/* '' */
.iconsminds-arrow-right-in-circle:before {
    content: '\e843';
}

/* '' */
.iconsminds-arrow-right:before {
    content: '\e844';
}

/* '' */
.iconsminds-arrow-turn-left:before {
    content: '\e845';
}

/* '' */
.iconsminds-arrow-turn-right:before {
    content: '\e846';
}

/* '' */
.iconsminds-arrow-up-2:before {
    content: '\e847';
}

/* '' */
.iconsminds-arrow-up-3:before {
    content: '\e848';
}

/* '' */
.iconsminds-arrow-up-in-circle:before {
    content: '\e849';
}

/* '' */
.iconsminds-arrow-up:before {
    content: '\e84a';
}

/* '' */
.iconsminds-arrow-x-left:before {
    content: '\e84b';
}

/* '' */
.iconsminds-arrow-x-right:before {
    content: '\e84c';
}

/* '' */
.iconsminds-bottom-to-top:before {
    content: '\e84d';
}

/* '' */
.iconsminds-down:before {
    content: '\e84e';
}

/* '' */
.iconsminds-down-2:before {
    content: '\e84f';
}

/* '' */
.iconsminds-down-3:before {
    content: '\e850';
}

/* '' */
.iconsminds-download:before {
    content: '\e851';
}

/* '' */
.iconsminds-end:before {
    content: '\e852';
}

/* '' */
.iconsminds-fit-to-2:before {
    content: '\e853';
}

/* '' */
.iconsminds-fit-to:before {
    content: '\e854';
}

/* '' */
.iconsminds-full-screen-2:before {
    content: '\e855';
}

/* '' */
.iconsminds-full-screen:before {
    content: '\e856';
}

/* '' */
.iconsminds-go-bottom:before {
    content: '\e857';
}

/* '' */
.iconsminds-go-top:before {
    content: '\e858';
}

/* '' */
.iconsminds-left---right-3:before {
    content: '\e859';
}

/* '' */
.iconsminds-left---right:before {
    content: '\e85a';
}

/* '' */
.iconsminds-left:before {
    content: '\e85b';
}

/* '' */
.iconsminds-left-2:before {
    content: '\e85c';
}

/* '' */
.iconsminds-left-3:before {
    content: '\e85d';
}

/* '' */
.iconsminds-left-to-right:before {
    content: '\e85e';
}

/* '' */
.iconsminds-loop:before {
    content: '\e85f';
}

/* '' */
.iconsminds-navigate-end:before {
    content: '\e860';
}

/* '' */
.iconsminds-navigat-start:before {
    content: '\e861';
}

/* '' */
.iconsminds-reload:before {
    content: '\e862';
}

/* '' */
.iconsminds-reload-2:before {
    content: '\e863';
}

/* '' */
.iconsminds-repeat:before {
    content: '\e864';
}

/* '' */
.iconsminds-repeat-2:before {
    content: '\e865';
}

/* '' */
.iconsminds-repeat-3:before {
    content: '\e866';
}

/* '' */
.iconsminds-repeat-4:before {
    content: '\e867';
}

/* '' */
.iconsminds-right:before {
    content: '\e868';
}

/* '' */
.iconsminds-right-2:before {
    content: '\e869';
}

/* '' */
.iconsminds-right-3:before {
    content: '\e86a';
}

/* '' */
.iconsminds-right-to-left:before {
    content: '\e86b';
}

/* '' */
.iconsminds-shuffle:before {
    content: '\e86c';
}

/* '' */
.iconsminds-shuffle-2:before {
    content: '\e86d';
}

/* '' */
.iconsminds-start:before {
    content: '\e86e';
}

/* '' */
.iconsminds-sync:before {
    content: '\e86f';
}

/* '' */
.iconsminds-to-bottom-2:before {
    content: '\e870';
}

/* '' */
.iconsminds-to-bottom:before {
    content: '\e871';
}

/* '' */
.iconsminds-to-left:before {
    content: '\e872';
}

/* '' */
.iconsminds-top-to-bottom:before {
    content: '\e873';
}

/* '' */
.iconsminds-to-right:before {
    content: '\e874';
}

/* '' */
.iconsminds-to-top-2:before {
    content: '\e875';
}

/* '' */
.iconsminds-to-top:before {
    content: '\e876';
}

/* '' */
.iconsminds-triangle-arrow-down:before {
    content: '\e877';
}

/* '' */
.iconsminds-triangle-arrow-left:before {
    content: '\e878';
}

/* '' */
.iconsminds-triangle-arrow-right:before {
    content: '\e879';
}

/* '' */
.iconsminds-triangle-arrow-up:before {
    content: '\e87a';
}

/* '' */
.iconsminds-turn-down-2:before {
    content: '\e87b';
}

/* '' */
.iconsminds-turn-down-from-left:before {
    content: '\e87c';
}

/* '' */
.iconsminds-turn-down-from-right:before {
    content: '\e87d';
}

/* '' */
.iconsminds-turn-down:before {
    content: '\e87e';
}

/* '' */
.iconsminds-turn-left-3:before {
    content: '\e87f';
}

/* '' */
.iconsminds-turn-left:before {
    content: '\e880';
}

/* '' */
.iconsminds-turn-right-3:before {
    content: '\e881';
}

/* '' */
.iconsminds-turn-right:before {
    content: '\e882';
}

/* '' */
.iconsminds-turn-up-2:before {
    content: '\e883';
}

/* '' */
.iconsminds-turn-up:before {
    content: '\e884';
}

/* '' */
.iconsminds-up---down-3:before {
    content: '\e885';
}

/* '' */
.iconsminds-up---down:before {
    content: '\e886';
}

/* '' */
.iconsminds-up:before {
    content: '\e887';
}

/* '' */
.iconsminds-up-2:before {
    content: '\e888';
}

/* '' */
.iconsminds-up-3:before {
    content: '\e889';
}

/* '' */
.iconsminds-upload:before {
    content: '\e88a';
}

/* '' */
.iconsminds-billing:before {
    content: '\e88b';
}

/* '' */
.iconsminds-binocular:before {
    content: '\e88c';
}

/* '' */
.iconsminds-bone:before {
    content: '\e88d';
}

/* '' */
.iconsminds-box-close:before {
    content: '\e88e';
}

/* '' */
.iconsminds-box-with-folders:before {
    content: '\e88f';
}

/* '' */
.iconsminds-brush:before {
    content: '\e890';
}

/* '' */
.iconsminds-bucket:before {
    content: '\e891';
}

/* '' */
.iconsminds-camera-3:before {
    content: '\e892';
}

/* '' */
.iconsminds-camera-4:before {
    content: '\e893';
}

/* '' */
.iconsminds-candle:before {
    content: '\e894';
}

/* '' */
.iconsminds-candy:before {
    content: '\e895';
}

/* '' */
.iconsminds-chair:before {
    content: '\e896';
}

/* '' */
.iconsminds-control:before {
    content: '\e897';
}

/* '' */
.iconsminds-control-2:before {
    content: '\e898';
}

/* '' */
.iconsminds-crop-2:before {
    content: '\e899';
}

/* '' */
.iconsminds-crown-2:before {
    content: '\e89a';
}

/* '' */
.iconsminds-dashboard:before {
    content: '\e89b';
}

/* '' */
.iconsminds-data-center:before {
    content: '\e89c';
}

/* '' */
.iconsminds-data-cloud:before {
    content: '\e89d';
}

/* '' */
.iconsminds-data-download:before {
    content: '\e89e';
}

/* '' */
.iconsminds-data-storage:before {
    content: '\e89f';
}

/* '' */
.iconsminds-delete-file:before {
    content: '\e8a0';
}

/* '' */
.iconsminds-dice:before {
    content: '\e8a1';
}

/* '' */
.iconsminds-drill:before {
    content: '\e8a2';
}

/* '' */
.iconsminds-duplicate-layer:before {
    content: '\e8a3';
}

/* '' */
.iconsminds-electricity:before {
    content: '\e8a4';
}

/* '' */
.iconsminds-factory:before {
    content: '\e8a5';
}

/* '' */
.iconsminds-feather:before {
    content: '\e8a6';
}

/* '' */
.iconsminds-file:before {
    content: '\e8a7';
}

/* '' */
.iconsminds-file-clipboard-file---text:before {
    content: '\e8a8';
}

/* '' */
.iconsminds-file-clipboard:before {
    content: '\e8a9';
}

/* '' */
.iconsminds-file-copy:before {
    content: '\e8aa';
}

/* '' */
.iconsminds-file-edit:before {
    content: '\e8ab';
}

/* '' */
.iconsminds-file-horizontal:before {
    content: '\e8ac';
}

/* '' */
.iconsminds-files:before {
    content: '\e8ad';
}

/* '' */
.iconsminds-file-zip:before {
    content: '\e8ae';
}

/* '' */
.iconsminds-filter-2:before {
    content: '\e8af';
}

/* '' */
.iconsminds-flash-2:before {
    content: '\e8b0';
}

/* '' */
.iconsminds-folder:before {
    content: '\e8b1';
}

/* '' */
.iconsminds-folder-add--:before {
    content: '\e8b2';
}

/* '' */
.iconsminds-folder-block:before {
    content: '\e8b3';
}

/* '' */
.iconsminds-folder-close:before {
    content: '\e8b4';
}

/* '' */
.iconsminds-folder-cloud:before {
    content: '\e8b5';
}

/* '' */
.iconsminds-folder-delete:before {
    content: '\e8b6';
}

/* '' */
.iconsminds-folder-edit:before {
    content: '\e8b7';
}

/* '' */
.iconsminds-folder-open:before {
    content: '\e8b8';
}

/* '' */
.iconsminds-folders:before {
    content: '\e8b9';
}

/* '' */
.iconsminds-folder-zip:before {
    content: '\e8ba';
}

/* '' */
.iconsminds-funny-bicycle:before {
    content: '\e8bb';
}

/* '' */
.iconsminds-gas-pump:before {
    content: '\e8bc';
}

/* '' */
.iconsminds-gear:before {
    content: '\e8bd';
}

/* '' */
.iconsminds-gear-2:before {
    content: '\e8be';
}

/* '' */
.iconsminds-gears:before {
    content: '\e8bf';
}

/* '' */
.iconsminds-gift-box:before {
    content: '\e8c1';
}

/* '' */
.iconsminds-grave:before {
    content: '\e8c2';
}

/* '' */
.iconsminds-headphone:before {
    content: '\e8c3';
}

/* '' */
.iconsminds-headset:before {
    content: '\e8c4';
}

/* '' */
.iconsminds-hipster-men:before {
    content: '\e8c5';
}

/* '' */
.iconsminds-hub:before {
    content: '\e8c6';
}

/* '' */
.iconsminds-idea:before {
    content: '\e8c7';
}

/* '' */
.iconsminds-information:before {
    content: '\e8c8';
}

/* '' */
.iconsminds-key:before {
    content: '\e8c9';
}

/* '' */
.iconsminds-knife:before {
    content: '\e8ca';
}

/* '' */
.iconsminds-lantern:before {
    content: '\e8cb';
}

/* '' */
.iconsminds-layer-backward:before {
    content: '\e8cc';
}

/* '' */
.iconsminds-layer-forward:before {
    content: '\e8cd';
}

/* '' */
.iconsminds-library:before {
    content: '\e8ce';
}

/* '' */
.iconsminds-light-bulb-2:before {
    content: '\e8d0';
}

/* '' */
.iconsminds-loading:before {
    content: '\e8d1';
}

/* '' */
.iconsminds-loading-2:before {
    content: '\e8d2';
}

/* '' */
.iconsminds-loading-3:before {
    content: '\e8d3';
}

/* '' */
.iconsminds-magic-wand:before {
    content: '\e8d4';
}

/* '' */
.iconsminds-magnifi-glass--:before {
    content: '\e8d5';
}

/* '' */
.iconsminds-magnifi-glass--:before {
    content: '\e8d6';
}

/* '' */
.iconsminds-magnifi-glass:before {
    content: '\e8d7';
}

/* '' */
.iconsminds-memory-card-2:before {
    content: '\e8d8';
}

/* '' */
.iconsminds-mine:before {
    content: '\e8d9';
}

/* '' */
.iconsminds-mustache-2:before {
    content: '\e8da';
}

/* '' */
.iconsminds-office-lamp:before {
    content: '\e8db';
}

/* '' */
.iconsminds-old-sticky-2:before {
    content: '\e8dc';
}

/* '' */
.iconsminds-on-off:before {
    content: '\e8dd';
}

/* '' */
.iconsminds-on-off-2:before {
    content: '\e8de';
}

/* '' */
.iconsminds-on-off-3:before {
    content: '\e8df';
}

/* '' */
.iconsminds-palette:before {
    content: '\e8e0';
}

/* '' */
.iconsminds-paper:before {
    content: '\e8e1';
}

/* '' */
.iconsminds-pen:before {
    content: '\e8e2';
}

/* '' */
.iconsminds-photo:before {
    content: '\e8e3';
}

/* '' */
.iconsminds-photo-album-2:before {
    content: '\e8e4';
}

/* '' */
.iconsminds-power-station:before {
    content: '\e8e5';
}

/* '' */
.iconsminds-preview:before {
    content: '\e8e6';
}

/* '' */
.iconsminds-pricing:before {
    content: '\e8e7';
}

/* '' */
.iconsminds-profile:before {
    content: '\e8e8';
}

/* '' */
.iconsminds-project:before {
    content: '\e8e9';
}

/* '' */
.iconsminds-puzzle:before {
    content: '\e8ea';
}

/* '' */
.iconsminds-refinery:before {
    content: '\e8eb';
}

/* '' */
.iconsminds-remove-file:before {
    content: '\e8ec';
}

/* '' */
.iconsminds-rename:before {
    content: '\e8ed';
}

/* '' */
.iconsminds-repair:before {
    content: '\e8ee';
}

/* '' */
.iconsminds-ruler:before {
    content: '\e8ef';
}

/* '' */
.iconsminds-save:before {
    content: '\e8f0';
}

/* '' */
.iconsminds-scissor:before {
    content: '\e8f2';
}

/* '' */
.iconsminds-scroller:before {
    content: '\e8f3';
}

/* '' */
.iconsminds-scroller-2:before {
    content: '\e8f4';
}

/* '' */
.iconsminds-share:before {
    content: '\e8f5';
}

/* '' */
.iconsminds-smoking-pipe:before {
    content: '\e8f6';
}

/* '' */
.iconsminds-solar:before {
    content: '\e8f7';
}

/* '' */
.iconsminds-statistic:before {
    content: '\e8f8';
}

/* '' */
.iconsminds-suitcase:before {
    content: '\e8f9';
}

/* '' */
.iconsminds-support:before {
    content: '\e8fa';
}

/* '' */
.iconsminds-switch:before {
    content: '\e8fb';
}

/* '' */
.iconsminds-tripod-with-camera:before {
    content: '\e8fc';
}

/* '' */
.iconsminds-upgrade:before {
    content: '\e8fd';
}

/* '' */
.iconsminds-user:before {
    content: '\e8fe';
}

/* '' */
.iconsminds-windmill:before {
    content: '\e8ff';
}

/* '' */
.iconsminds-witch-hat:before {
    content: '\e900';
}

/* '' */
.iconsminds-wrench:before {
    content: '\e901';
}

/* '' */
.iconsminds-add-file:before {
    content: '\e902';
}

/* '' */
.iconsminds-affiliate:before {
    content: '\e903';
}

/* '' */
.iconsminds-anchor:before {
    content: '\e904';
}

/* '' */
.iconsminds-balloon:before {
    content: '\e905';
}

/* '' */
.iconsminds-beard-3:before {
    content: '\e906';
}

/* '' */
.iconsminds-bicycle:before {
    content: '\e907';
}

/* '' */
.iconsminds-big-data:before {
    content: '\e908';
}

/* '' */
.iconsminds-eifel-tower:before {
    content: '\e909';
}

/* '' */
.iconsminds-el-castillo:before {
    content: '\e90a';
}

/* '' */
.iconsminds-embassy:before {
    content: '\e90b';
}

/* '' */
.iconsminds-empire-state-building:before {
    content: '\e90c';
}

/* '' */
.iconsminds-factory-1:before {
    content: '\e90d';
}

/* '' */
.iconsminds-fire-staion:before {
    content: '\e90e';
}

/* '' */
.iconsminds-home:before {
    content: '\e90f';
}

/* '' */
.iconsminds-home-3:before {
    content: '\e910';
}

/* '' */
.iconsminds-home-4:before {
    content: '\e911';
}

/* '' */
.iconsminds-hotel:before {
    content: '\e912';
}

/* '' */
.iconsminds-japanese-gate:before {
    content: '\e913';
}

/* '' */
.iconsminds-leaning-tower:before {
    content: '\e914';
}

/* '' */
.iconsminds-lighthouse:before {
    content: '\e915';
}

/* '' */
.iconsminds-museum:before {
    content: '\e916';
}

/* '' */
.iconsminds-office:before {
    content: '\e917';
}

/* '' */
.iconsminds-opera-house:before {
    content: '\e918';
}

/* '' */
.iconsminds-piramids:before {
    content: '\e91b';
}

/* '' */
.iconsminds-police-station:before {
    content: '\e91c';
}

/* '' */
.iconsminds-post-office:before {
    content: '\e91d';
}

/* '' */
.iconsminds-prater:before {
    content: '\e91e';
}

/* '' */
.iconsminds-roof:before {
    content: '\e91f';
}

/* '' */
.iconsminds-space-needle:before {
    content: '\e920';
}

/* '' */
.iconsminds-the-white-house:before {
    content: '\e922';
}

/* '' */
.iconsminds-tower:before {
    content: '\e923';
}

/* '' */
.iconsminds-bank:before {
    content: '\e924';
}

/* '' */
.iconsminds-berlin-tower:before {
    content: '\e925';
}

/* '' */
.iconsminds-big-bang:before {
    content: '\e926';
}

/* '' */
.iconsminds-building:before {
    content: '\e927';
}

/* '' */
.iconsminds-castle:before {
    content: '\e928';
}

/* '' */
.iconsminds-chinese-temple:before {
    content: '\e929';
}

/* '' */
.iconsminds-chrysler-building:before {
    content: '\e92a';
}

/* '' */
.iconsminds-city-hall:before {
    content: '\e92b';
}

/* '' */
.iconsminds-clothing-store:before {
    content: '\e92c';
}

/* '' */
.iconsminds-colosseum:before {
    content: '\e92d';
}

/* '' */
.iconsminds-column:before {
    content: '\e92e';
}

/* '' */
.iconsminds-coins:before {
    content: '\e92f';
}

/* '' */
.iconsminds-coins-2:before {
    content: '\e930';
}

/* '' */
.iconsminds-diamond:before {
    content: '\e933';
}

/* '' */
.iconsminds-dollar:before {
    content: '\e934';
}

/* '' */
.iconsminds-dollar-sign-2:before {
    content: '\e935';
}

/* '' */
.iconsminds-euro:before {
    content: '\e936';
}

/* '' */
.iconsminds-euro-sign-2:before {
    content: '\e937';
}

/* '' */
.iconsminds-financial:before {
    content: '\e938';
}

/* '' */
.iconsminds-handshake:before {
    content: '\e939';
}

/* '' */
.iconsminds-pie-chart-3:before {
    content: '\e93c';
}

/* '' */
.iconsminds-pie-chart:before {
    content: '\e93d';
}

/* '' */
.iconsminds-pound:before {
    content: '\e93e';
}

/* '' */
.iconsminds-pound-sign-2:before {
    content: '\e93f';
}

/* '' */
.iconsminds-safe-box:before {
    content: '\e940';
}

/* '' */
.iconsminds-wallet:before {
    content: '\e941';
}

/* '' */
.iconsminds-bar-chart-4:before {
    content: '\e942';
}

/* '' */
.iconsminds-jeans:before {
    content: '\e946';
}

/* '' */
.iconsminds-sunglasses-w-3:before {
    content: '\e947';
}

/* '' */
.iconsminds-tie:before {
    content: '\e948';
}

/* '' */
.iconsminds-t-shirt:before {
    content: '\e949';
}

/* '' */
.iconsminds-baby-clothes:before {
    content: '\e94a';
}

/* '' */
.iconsminds-belt:before {
    content: '\e94b';
}

/* '' */
.iconsminds-bikini:before {
    content: '\e94c';
}

/* '' */
.iconsminds-blouse:before {
    content: '\e94d';
}

/* '' */
.iconsminds-boot:before {
    content: '\e94e';
}

/* '' */
.iconsminds-bow-3:before {
    content: '\e94f';
}

/* '' */
.iconsminds-bra:before {
    content: '\e950';
}

/* '' */
.iconsminds-cap:before {
    content: '\e951';
}

/* '' */
.iconsminds-coat:before {
    content: '\e952';
}

/* '' */
.iconsminds-dress:before {
    content: '\e953';
}

/* '' */
.iconsminds-glasses-3:before {
    content: '\e954';
}

/* '' */
.iconsminds-gloves:before {
    content: '\e955';
}

/* '' */
.iconsminds-hanger:before {
    content: '\e956';
}

/* '' */
.iconsminds-heels-2:before {
    content: '\e957';
}

/* '' */
.iconsminds-jacket:before {
    content: '\e958';
}

/* '' */
.iconsminds-walkie-talkie:before {
    content: '\e959';
}

/* '' */
.iconsminds-wifi:before {
    content: '\e95a';
}

/* '' */
.iconsminds-address-book-2:before {
    content: '\e95b';
}

/* '' */
.iconsminds-bell:before {
    content: '\e95c';
}

/* '' */
.iconsminds-bird-delivering-letter:before {
    content: '\e95d';
}

/* '' */
.iconsminds-communication-tower-2:before {
    content: '\e95e';
}

/* '' */
.iconsminds-fax:before {
    content: '\e95f';
}

/* '' */
.iconsminds-megaphone:before {
    content: '\e960';
}

/* '' */
.iconsminds-newspaper:before {
    content: '\e961';
}

/* '' */
.iconsminds-old-telephone:before {
    content: '\e962';
}

/* '' */
.iconsminds-router:before {
    content: '\e963';
}

/* '' */
.iconsminds-telephone-2:before {
    content: '\e964';
}

/* '' */
.iconsminds-smartphone-4:before {
    content: '\e965';
}

/* '' */
.iconsminds-tablet-3:before {
    content: '\e966';
}

/* '' */
.iconsminds-computer:before {
    content: '\e967';
}

/* '' */
.iconsminds-laptop---phone:before {
    content: '\e968';
}

/* '' */
.iconsminds-laptop---tablet:before {
    content: '\e969';
}

/* '' */
.iconsminds-laptop-3:before {
    content: '\e96a';
}

/* '' */
.iconsminds-monitor:before {
    content: '\e96b';
}

/* '' */
.iconsminds-monitor---laptop:before {
    content: '\e96c';
}

/* '' */
.iconsminds-monitor---phone:before {
    content: '\e96d';
}

/* '' */
.iconsminds-monitor---tablet:before {
    content: '\e96e';
}

/* '' */
.iconsminds-monitor-3:before {
    content: '\e96f';
}

/* '' */
.iconsminds-monitor-vertical:before {
    content: '\e970';
}

/* '' */
.iconsminds-orientation:before {
    content: '\e971';
}

/* '' */
.iconsminds-phone-3:before {
    content: '\e972';
}

/* '' */
.iconsminds-smartphone-3:before {
    content: '\e973';
}

/* '' */
.iconsminds-quill-3:before {
    content: '\e974';
}

/* '' */
.iconsminds-student-hat:before {
    content: '\e975';
}

/* '' */
.iconsminds-blackboard:before {
    content: '\e976';
}

/* '' */
.iconsminds-book:before {
    content: '\e977';
}

/* '' */
.iconsminds-bookmark:before {
    content: '\e978';
}

/* '' */
.iconsminds-books:before {
    content: '\e979';
}

/* '' */
.iconsminds-compass-2:before {
    content: '\e97a';
}

/* '' */
.iconsminds-diploma-2:before {
    content: '\e97b';
}

/* '' */
.iconsminds-eraser-2:before {
    content: '\e97c';
}

/* '' */
.iconsminds-formula:before {
    content: '\e97d';
}

/* '' */
.iconsminds-notepad:before {
    content: '\e97e';
}

/* '' */
.iconsminds-open-book:before {
    content: '\e97f';
}

/* '' */
.iconsminds-pen-2:before {
    content: '\e980';
}

/* '' */
.iconsminds-pi:before {
    content: '\e981';
}

/* '' */
.iconsminds-pipette:before {
    content: '\e982';
}

/* '' */
.iconsminds-mail-block:before {
    content: '\e983';
}

/* '' */
.iconsminds-mailbox-empty:before {
    content: '\e984';
}

/* '' */
.iconsminds-mailbox-full:before {
    content: '\e985';
}

/* '' */
.iconsminds-mail-delete:before {
    content: '\e986';
}

/* '' */
.iconsminds-mail-favorite:before {
    content: '\e987';
}

/* '' */
.iconsminds-mail-forward:before {
    content: '\e988';
}

/* '' */
.iconsminds-mail-gallery:before {
    content: '\e989';
}

/* '' */
.iconsminds-mail-inbox:before {
    content: '\e98a';
}

/* '' */
.iconsminds-mail-link:before {
    content: '\e98b';
}

/* '' */
.iconsminds-mail-lock:before {
    content: '\e98c';
}

/* '' */
.iconsminds-mail-love:before {
    content: '\e98d';
}

/* '' */
.iconsminds-mail-money:before {
    content: '\e98e';
}

/* '' */
.iconsminds-mail-open:before {
    content: '\e98f';
}

/* '' */
.iconsminds-mail-outbox:before {
    content: '\e990';
}

/* '' */
.iconsminds-mail-password:before {
    content: '\e991';
}

/* '' */
.iconsminds-mail-photo:before {
    content: '\e992';
}

/* '' */
.iconsminds-mail-read:before {
    content: '\e993';
}

/* '' */
.iconsminds-mail-remove-x:before {
    content: '\e994';
}

/* '' */
.iconsminds-mail-reply-all:before {
    content: '\e995';
}

/* '' */
.iconsminds-mail-reply:before {
    content: '\e996';
}

/* '' */
.iconsminds-mail-search:before {
    content: '\e997';
}

/* '' */
.iconsminds-mail-send:before {
    content: '\e998';
}

/* '' */
.iconsminds-mail-settings:before {
    content: '\e999';
}

/* '' */
.iconsminds-mail-unread:before {
    content: '\e99a';
}

/* '' */
.iconsminds-mail-video:before {
    content: '\e99b';
}

/* '' */
.iconsminds-mail-with-at-sign:before {
    content: '\e99c';
}

/* '' */
.iconsminds-mail-with-cursors:before {
    content: '\e99d';
}

/* '' */
.iconsminds-new-mail:before {
    content: '\e99e';
}

/* '' */
.iconsminds-post-mail-2:before {
    content: '\e99f';
}

/* '' */
.iconsminds-post-mail:before {
    content: '\e9a0';
}

/* '' */
.iconsminds-spam-mail:before {
    content: '\e9a1';
}

/* '' */
.iconsminds-stamp:before {
    content: '\e9a2';
}

/* '' */
.iconsminds-stamp-2:before {
    content: '\e9a3';
}

/* '' */
.iconsminds-voicemail:before {
    content: '\e9a4';
}

/* '' */
.iconsminds-at-sign:before {
    content: '\e9a5';
}

/* '' */
.iconsminds-box-full:before {
    content: '\e9a6';
}

/* '' */
.iconsminds-empty-box:before {
    content: '\e9a7';
}

/* '' */
.iconsminds-envelope:before {
    content: '\e9a8';
}

/* '' */
.iconsminds-envelope-2:before {
    content: '\e9a9';
}

/* '' */
.iconsminds-inbox:before {
    content: '\e9aa';
}

/* '' */
.iconsminds-inbox-empty:before {
    content: '\e9ab';
}

/* '' */
.iconsminds-inbox-forward:before {
    content: '\e9ac';
}

/* '' */
.iconsminds-inbox-full:before {
    content: '\e9ad';
}

/* '' */
.iconsminds-inbox-into:before {
    content: '\e9ae';
}

/* '' */
.iconsminds-inbox-out:before {
    content: '\e9af';
}

/* '' */
.iconsminds-inbox-reply:before {
    content: '\e9b0';
}

/* '' */
.iconsminds-letter-close:before {
    content: '\e9b1';
}

/* '' */
.iconsminds-letter-open:before {
    content: '\e9b2';
}

/* '' */
.iconsminds-letter-sent:before {
    content: '\e9b3';
}

/* '' */
.iconsminds-mail:before {
    content: '\e9b4';
}

/* '' */
.iconsminds-mail-2:before {
    content: '\e9b5';
}

/* '' */
.iconsminds-mail-3:before {
    content: '\e9b6';
}

/* '' */
.iconsminds-mail-add--:before {
    content: '\e9b7';
}

/* '' */
.iconsminds-mail-attachement:before {
    content: '\e9b8';
}

/* '' */
.iconsminds-ice-cream:before {
    content: '\e9b9';
}

/* '' */
.iconsminds-lollipop:before {
    content: '\e9ba';
}

/* '' */
.iconsminds-open-banana:before {
    content: '\e9bb';
}

/* '' */
.iconsminds-pepper:before {
    content: '\e9bc';
}

/* '' */
.iconsminds-tee-mug:before {
    content: '\e9bd';
}

/* '' */
.iconsminds-tomato:before {
    content: '\e9be';
}

/* '' */
.iconsminds-apple:before {
    content: '\e9bf';
}

/* '' */
.iconsminds-apple-bite:before {
    content: '\e9c0';
}

/* '' */
.iconsminds-beer-glass:before {
    content: '\e9c1';
}

/* '' */
.iconsminds-birthday-cake:before {
    content: '\e9c2';
}

/* '' */
.iconsminds-bread:before {
    content: '\e9c3';
}

/* '' */
.iconsminds-cake:before {
    content: '\e9c4';
}

/* '' */
.iconsminds-can:before {
    content: '\e9c5';
}

/* '' */
.iconsminds-can-2:before {
    content: '\e9c6';
}

/* '' */
.iconsminds-cheese:before {
    content: '\e9c7';
}

/* '' */
.iconsminds-chef-hat:before {
    content: '\e9c8';
}

/* '' */
.iconsminds-chopsticks:before {
    content: '\e9c9';
}

/* '' */
.iconsminds-cocktail:before {
    content: '\e9ca';
}

/* '' */
.iconsminds-coffee:before {
    content: '\e9cb';
}

/* '' */
.iconsminds-coffee-bean:before {
    content: '\e9cc';
}

/* '' */
.iconsminds-coffee-to-go:before {
    content: '\e9cd';
}

/* '' */
.iconsminds-cookies:before {
    content: '\e9ce';
}

/* '' */
.iconsminds-croissant:before {
    content: '\e9cf';
}

/* '' */
.iconsminds-cupcake:before {
    content: '\e9d0';
}

/* '' */
.iconsminds-doughnut:before {
    content: '\e9d1';
}

/* '' */
.iconsminds-fish:before {
    content: '\e9d2';
}

/* '' */
.iconsminds-glass-water:before {
    content: '\e9d3';
}

/* '' */
.iconsminds-hamburger:before {
    content: '\e9d4';
}

/* '' */
.iconsminds-hot-dog:before {
    content: '\e9d5';
}

/* '' */
.iconsminds-webcam:before {
    content: '\e9d7';
}

/* '' */
.iconsminds-battery-0:before {
    content: '\e9d8';
}

/* '' */
.iconsminds-battery-100:before {
    content: '\e9d9';
}

/* '' */
.iconsminds-battery-charge:before {
    content: '\e9da';
}

/* '' */
.iconsminds-charger:before {
    content: '\e9db';
}

/* '' */
.iconsminds-cpu:before {
    content: '\e9dc';
}

/* '' */
.iconsminds-disk:before {
    content: '\e9dd';
}

/* '' */
.iconsminds-dvd:before {
    content: '\e9de';
}

/* '' */
.iconsminds-fan:before {
    content: '\e9df';
}

/* '' */
.iconsminds-gamepad-2:before {
    content: '\e9e0';
}

/* '' */
.iconsminds-hdd:before {
    content: '\e9e1';
}

/* '' */
.iconsminds-keyboard:before {
    content: '\e9e2';
}

/* '' */
.iconsminds-mouse:before {
    content: '\e9e3';
}

/* '' */
.iconsminds-mouse-3:before {
    content: '\e9e4';
}

/* '' */
.iconsminds-plug-in:before {
    content: '\e9e5';
}

/* '' */
.iconsminds-power:before {
    content: '\e9e6';
}

/* '' */
.iconsminds-power-cable:before {
    content: '\e9e7';
}

/* '' */
.iconsminds-remote-controll-2:before {
    content: '\e9e8';
}

/* '' */
.iconsminds-server-2:before {
    content: '\e9e9';
}

/* '' */
.iconsminds-speaker:before {
    content: '\e9ea';
}

/* '' */
.iconsminds-start-ways:before {
    content: '\e9eb';
}

/* '' */
.iconsminds-synchronize:before {
    content: '\e9ec';
}

/* '' */
.iconsminds-synchronize-2:before {
    content: '\e9ed';
}

/* '' */
.iconsminds-undo:before {
    content: '\e9ee';
}

/* '' */
.iconsminds-up-1:before {
    content: '\e9ef';
}

/* '' */
.iconsminds-upload-1:before {
    content: '\e9f0';
}

/* '' */
.iconsminds-upward:before {
    content: '\e9f1';
}

/* '' */
.iconsminds-yes:before {
    content: '\e9f2';
}

/* '' */
.iconsminds-add:before {
    content: '\e9f3';
}

/* '' */
.iconsminds-back:before {
    content: '\e9f4';
}

/* '' */
.iconsminds-broken-link:before {
    content: '\e9f5';
}

/* '' */
.iconsminds-check:before {
    content: '\e9f6';
}

/* '' */
.iconsminds-close:before {
    content: '\e9f8';
}

/* '' */
.iconsminds-cursor:before {
    content: '\e9f9';
}

/* '' */
.iconsminds-cursor-click-2:before {
    content: '\e9fa';
}

/* '' */
.iconsminds-cursor-click:before {
    content: '\e9fb';
}

/* '' */
.iconsminds-cursor-move-2:before {
    content: '\e9fc';
}

/* '' */
.iconsminds-cursor-select:before {
    content: '\e9fd';
}

/* '' */
.iconsminds-down-1:before {
    content: '\e9fe';
}

/* '' */
.iconsminds-download-1:before {
    content: '\e9ff';
}

/* '' */
.iconsminds-downward:before {
    content: '\ea00';
}

/* '' */
.iconsminds-endways:before {
    content: '\ea01';
}

/* '' */
.iconsminds-forward:before {
    content: '\ea02';
}

/* '' */
.iconsminds-left-1:before {
    content: '\ea03';
}

/* '' */
.iconsminds-link:before {
    content: '\ea04';
}

/* '' */
.iconsminds-next:before {
    content: '\ea05';
}

/* '' */
.iconsminds-orientation-1:before {
    content: '\ea06';
}

/* '' */
.iconsminds-pointer:before {
    content: '\ea07';
}

/* '' */
.iconsminds-previous:before {
    content: '\ea08';
}

/* '' */
.iconsminds-redo:before {
    content: '\ea09';
}

/* '' */
.iconsminds-refresh:before {
    content: '\ea0a';
}

/* '' */
.iconsminds-reload-1:before {
    content: '\ea0b';
}

/* '' */
.iconsminds-remove:before {
    content: '\ea0c';
}

/* '' */
.iconsminds-repeat-1:before {
    content: '\ea0d';
}

/* '' */
.iconsminds-reset:before {
    content: '\ea0e';
}

/* '' */
.iconsminds-rewind:before {
    content: '\ea0f';
}

/* '' */
.iconsminds-right-1:before {
    content: '\ea10';
}

/* '' */
.iconsminds-rotation:before {
    content: '\ea11';
}

/* '' */
.iconsminds-rotation-390:before {
    content: '\ea12';
}

/* '' */
.iconsminds-spot:before {
    content: '\ea13';
}

/* '' */
.iconsminds-satelite-2:before {
    content: '\ea15';
}

/* '' */
.iconsminds-compass-1:before {
    content: '\ea16';
}

/* '' */
.iconsminds-direction-east:before {
    content: '\ea17';
}

/* '' */
.iconsminds-edit-map:before {
    content: '\ea18';
}

/* '' */
.iconsminds-geo2:before {
    content: '\ea19';
}

/* '' */
.iconsminds-geo2--:before {
    content: '\ea1a';
}

/* '' */
.iconsminds-geo2--:before {
    content: '\ea1b';
}

/* '' */
.iconsminds-globe-2:before {
    content: '\ea1c';
}

/* '' */
.iconsminds-location-2:before {
    content: '\ea1d';
}

/* '' */
.iconsminds-map2:before {
    content: '\ea1e';
}

/* '' */
.iconsminds-map-marker-2:before {
    content: '\ea1f';
}

/* '' */
.iconsminds-map-marker:before {
    content: '\ea20';
}

/* '' */
.iconsminds-stop:before {
    content: '\ea21';
}

/* '' */
.iconsminds-stop-2:before {
    content: '\ea22';
}

/* '' */
.iconsminds-back-1:before {
    content: '\ea23';
}

/* '' */
.iconsminds-back-2:before {
    content: '\ea24';
}

/* '' */
.iconsminds-eject:before {
    content: '\ea25';
}

/* '' */
.iconsminds-eject-2:before {
    content: '\ea26';
}

/* '' */
.iconsminds-end-1:before {
    content: '\ea27';
}

/* '' */
.iconsminds-end-2:before {
    content: '\ea28';
}

/* '' */
.iconsminds-next-1:before {
    content: '\ea29';
}

/* '' */
.iconsminds-next-2:before {
    content: '\ea2a';
}

/* '' */
.iconsminds-pause:before {
    content: '\ea2b';
}

/* '' */
.iconsminds-pause-2:before {
    content: '\ea2c';
}

/* '' */
.iconsminds-power-2:before {
    content: '\ea2d';
}

/* '' */
.iconsminds-power-3:before {
    content: '\ea2e';
}

/* '' */
.iconsminds-record:before {
    content: '\ea2f';
}

/* '' */
.iconsminds-record-2:before {
    content: '\ea30';
}

/* '' */
.iconsminds-repeat-5:before {
    content: '\ea31';
}

/* '' */
.iconsminds-repeat-6:before {
    content: '\ea32';
}

/* '' */
.iconsminds-shuffle-1:before {
    content: '\ea33';
}

/* '' */
.iconsminds-shuffle-3:before {
    content: '\ea34';
}

/* '' */
.iconsminds-start-1:before {
    content: '\ea35';
}

/* '' */
.iconsminds-start-2:before {
    content: '\ea36';
}

/* '' */
.iconsminds-volume-down:before {
    content: '\ea37';
}

/* '' */
.iconsminds-volume-up:before {
    content: '\ea38';
}

/* '' */
.iconsminds-back-music:before {
    content: '\ea39';
}

/* '' */
.iconsminds-cd-2:before {
    content: '\ea3a';
}

/* '' */
.iconsminds-clef:before {
    content: '\ea3b';
}

/* '' */
.iconsminds-earphones-2:before {
    content: '\ea3c';
}

/* '' */
.iconsminds-equalizer:before {
    content: '\ea3e';
}

/* '' */
.iconsminds-first:before {
    content: '\ea3f';
}

/* '' */
.iconsminds-headphones:before {
    content: '\ea41';
}

/* '' */
.iconsminds-last:before {
    content: '\ea42';
}

/* '' */
.iconsminds-loudspeaker:before {
    content: '\ea43';
}

/* '' */
.iconsminds-mic:before {
    content: '\ea44';
}

/* '' */
.iconsminds-microphone-4:before {
    content: '\ea45';
}

/* '' */
.iconsminds-next-music:before {
    content: '\ea46';
}

/* '' */
.iconsminds-old-radio:before {
    content: '\ea47';
}

/* '' */
.iconsminds-play-music:before {
    content: '\ea48';
}

/* '' */
.iconsminds-radio:before {
    content: '\ea49';
}

/* '' */
.iconsminds-record-1:before {
    content: '\ea4a';
}

/* '' */
.iconsminds-record-music:before {
    content: '\ea4b';
}

/* '' */
.iconsminds-sound:before {
    content: '\ea4c';
}

/* '' */
.iconsminds-speaker-1:before {
    content: '\ea4d';
}

/* '' */
.iconsminds-stop-music:before {
    content: '\ea4e';
}

/* '' */
.iconsminds-trumpet:before {
    content: '\ea4f';
}

/* '' */
.iconsminds-voice:before {
    content: '\ea50';
}

/* '' */
.iconsminds-tree-3:before {
    content: '\ea51';
}

/* '' */
.iconsminds-eci-icon:before {
    content: '\ea52';
}

/* '' */
.iconsminds-environmental:before {
    content: '\ea53';
}

/* '' */
.iconsminds-environmental-3:before {
    content: '\ea54';
}

/* '' */
.iconsminds-fire-flame-2:before {
    content: '\ea55';
}

/* '' */
.iconsminds-green-energy:before {
    content: '\ea58';
}

/* '' */
.iconsminds-green-house:before {
    content: '\ea59';
}

/* '' */
.iconsminds-leafs:before {
    content: '\ea5b';
}

/* '' */
.iconsminds-light-bulb-leaf:before {
    content: '\ea5c';
}

/* '' */
.iconsminds-palm-tree:before {
    content: '\ea5d';
}

/* '' */
.iconsminds-plant:before {
    content: '\ea5e';
}

/* '' */
.iconsminds-recycling-2:before {
    content: '\ea5f';
}

/* '' */
.iconsminds-seed:before {
    content: '\ea60';
}

/* '' */
.iconsminds-trash-with-men:before {
    content: '\ea61';
}

/* '' */
.iconsminds-id-card:before {
    content: '\ea62';
}

/* '' */
.iconsminds-king-2:before {
    content: '\ea63';
}

/* '' */
.iconsminds-male:before {
    content: '\ea64';
}

/* '' */
.iconsminds-male-female:before {
    content: '\ea65';
}

/* '' */
.iconsminds-male-2:before {
    content: '\ea66';
}

/* '' */
.iconsminds-man-sign:before {
    content: '\ea67';
}

/* '' */
.iconsminds-mens:before {
    content: '\ea68';
}

/* '' */
.iconsminds-network:before {
    content: '\ea69';
}

/* '' */
.iconsminds-student-female:before {
    content: '\ea6a';
}

/* '' */
.iconsminds-student-male:before {
    content: '\ea6b';
}

/* '' */
.iconsminds-student-male-female:before {
    content: '\ea6c';
}

/* '' */
.iconsminds-students:before {
    content: '\ea6d';
}

/* '' */
.iconsminds-woman-man:before {
    content: '\ea6e';
}

/* '' */
.iconsminds-add-user:before {
    content: '\ea6f';
}

/* '' */
.iconsminds-administrator:before {
    content: '\ea70';
}

/* '' */
.iconsminds-assistant:before {
    content: '\ea71';
}

/* '' */
.iconsminds-business-man:before {
    content: '\ea72';
}

/* '' */
.iconsminds-business-man-woman:before {
    content: '\ea73';
}

/* '' */
.iconsminds-business-mens:before {
    content: '\ea74';
}

/* '' */
.iconsminds-business-woman:before {
    content: '\ea75';
}

/* '' */
.iconsminds-conference:before {
    content: '\ea76';
}

/* '' */
.iconsminds-doctor:before {
    content: '\ea77';
}

/* '' */
.iconsminds-engineering:before {
    content: '\ea78';
}

/* '' */
.iconsminds-female:before {
    content: '\ea79';
}

/* '' */
.iconsminds-female-2:before {
    content: '\ea7a';
}

/* '' */
.iconsminds-temperature:before {
    content: '\ea7b';
}

/* '' */
.iconsminds-test-tube:before {
    content: '\ea7c';
}

/* '' */
.iconsminds-ambulance:before {
    content: '\ea7d';
}

/* '' */
.iconsminds-atom:before {
    content: '\ea7e';
}

/* '' */
.iconsminds-band-aid:before {
    content: '\ea7f';
}

/* '' */
.iconsminds-bio-hazard:before {
    content: '\ea80';
}

/* '' */
.iconsminds-biotech:before {
    content: '\ea81';
}

/* '' */
.iconsminds-brain:before {
    content: '\ea82';
}

/* '' */
.iconsminds-chemical:before {
    content: '\ea83';
}

/* '' */
.iconsminds-clinic:before {
    content: '\ea84';
}

/* '' */
.iconsminds-danger:before {
    content: '\ea85';
}

/* '' */
.iconsminds-dna:before {
    content: '\ea86';
}

/* '' */
.iconsminds-dna-2:before {
    content: '\ea87';
}

/* '' */
.iconsminds-first-aid:before {
    content: '\ea88';
}

/* '' */
.iconsminds-flask:before {
    content: '\ea89';
}

/* '' */
.iconsminds-medical-sign:before {
    content: '\ea8a';
}

/* '' */
.iconsminds-medicine-3:before {
    content: '\ea8b';
}

/* '' */
.iconsminds-microscope:before {
    content: '\ea8c';
}

/* '' */
.iconsminds-physics:before {
    content: '\ea8d';
}

/* '' */
.iconsminds-plasmid:before {
    content: '\ea8e';
}

/* '' */
.iconsminds-plaster:before {
    content: '\ea8f';
}

/* '' */
.iconsminds-pulse:before {
    content: '\ea90';
}

/* '' */
.iconsminds-radioactive:before {
    content: '\ea91';
}

/* '' */
.iconsminds-stethoscope:before {
    content: '\ea92';
}

/* '' */
.iconsminds-security-settings:before {
    content: '\ea93';
}

/* '' */
.iconsminds-securiy-remove:before {
    content: '\ea94';
}

/* '' */
.iconsminds-shield:before {
    content: '\ea95';
}

/* '' */
.iconsminds-ssl:before {
    content: '\ea96';
}

/* '' */
.iconsminds-type-pass:before {
    content: '\ea97';
}

/* '' */
.iconsminds-unlock-2:before {
    content: '\ea98';
}

/* '' */
.iconsminds-finger-print:before {
    content: '\ea99';
}

/* '' */
.iconsminds-firewall:before {
    content: '\ea9a';
}

/* '' */
.iconsminds-key-lock:before {
    content: '\ea9b';
}

/* '' */
.iconsminds-laptop-secure:before {
    content: '\ea9c';
}

/* '' */
.iconsminds-lock-2:before {
    content: '\ea9d';
}

/* '' */
.iconsminds-password:before {
    content: '\ea9e';
}

/* '' */
.iconsminds-password-field:before {
    content: '\ea9f';
}

/* '' */
.iconsminds-police:before {
    content: '\eaa0';
}

/* '' */
.iconsminds-security-block:before {
    content: '\eaa2';
}

/* '' */
.iconsminds-security-bug:before {
    content: '\eaa3';
}

/* '' */
.iconsminds-security-camera:before {
    content: '\eaa4';
}

/* '' */
.iconsminds-security-check:before {
    content: '\eaa5';
}

/* '' */
.iconsminds-testimonal:before {
    content: '\eaa6';
}

/* '' */
.iconsminds-broke-link-2:before {
    content: '\eaa7';
}

/* '' */
.iconsminds-coding:before {
    content: '\eaa8';
}

/* '' */
.iconsminds-consulting:before {
    content: '\eaa9';
}

/* '' */
.iconsminds-copyright:before {
    content: '\eaaa';
}

/* '' */
.iconsminds-idea-2:before {
    content: '\eaab';
}

/* '' */
.iconsminds-link-2:before {
    content: '\eaac';
}

/* '' */
.iconsminds-management:before {
    content: '\eaad';
}

/* '' */
.iconsminds-monitor-analytics:before {
    content: '\eaae';
}

/* '' */
.iconsminds-monitoring:before {
    content: '\eaaf';
}

/* '' */
.iconsminds-optimization:before {
    content: '\eab0';
}

/* '' */
.iconsminds-tag:before {
    content: '\eab1';
}

/* '' */
.iconsminds-target:before {
    content: '\eab2';
}

/* '' */
.iconsminds-target-market:before {
    content: '\eab3';
}

/* '' */
.iconsminds-shopping-bag:before {
    content: '\eab5';
}

/* '' */
.iconsminds-shopping-basket:before {
    content: '\eab6';
}

/* '' */
.iconsminds-shopping-cart:before {
    content: '\eab7';
}

/* '' */
.iconsminds-tag-3:before {
    content: '\eab8';
}

/* '' */
.iconsminds-add-bag:before {
    content: '\eab9';
}

/* '' */
.iconsminds-add-basket:before {
    content: '\eaba';
}

/* '' */
.iconsminds-add-cart:before {
    content: '\eabb';
}

/* '' */
.iconsminds-bag-items:before {
    content: '\eabc';
}

/* '' */
.iconsminds-bag-quantity:before {
    content: '\eabd';
}

/* '' */
.iconsminds-basket-coins:before {
    content: '\eabf';
}

/* '' */
.iconsminds-basket-items:before {
    content: '\eac0';
}

/* '' */
.iconsminds-basket-quantity:before {
    content: '\eac1';
}

/* '' */
.iconsminds-car-items:before {
    content: '\eac2';
}

/* '' */
.iconsminds-cart-quantity:before {
    content: '\eac3';
}

/* '' */
.iconsminds-cash-register-2:before {
    content: '\eac4';
}

/* '' */
.iconsminds-checkout:before {
    content: '\eac5';
}

/* '' */
.iconsminds-checkout-bag:before {
    content: '\eac6';
}

/* '' */
.iconsminds-checkout-basket:before {
    content: '\eac7';
}

/* '' */
.iconsminds-home-1:before {
    content: '\eac8';
}

/* '' */
.iconsminds-qr-code:before {
    content: '\eac9';
}

/* '' */
.iconsminds-receipt-4:before {
    content: '\eaca';
}

/* '' */
.iconsminds-remove-bag:before {
    content: '\eacb';
}

/* '' */
.iconsminds-remove-basket:before {
    content: '\eacc';
}

/* '' */
.iconsminds-remove-cart:before {
    content: '\eacd';
}

/* '' */
.iconsminds-shop:before {
    content: '\eace';
}

/* '' */
.iconsminds-shop-2:before {
    content: '\eacf';
}

/* '' */
.iconsminds-shop-3:before {
    content: '\ead0';
}

/* '' */
.iconsminds-ying-yang:before {
    content: '\ead1';
}

/* '' */
.iconsminds-bisexual:before {
    content: '\ead2';
}

/* '' */
.iconsminds-cancer:before {
    content: '\ead3';
}

/* '' */
.iconsminds-couple-sign:before {
    content: '\ead4';
}

/* '' */
.iconsminds-family-sign:before {
    content: '\ead5';
}

/* '' */
.iconsminds-female-1:before {
    content: '\ead6';
}

/* '' */
.iconsminds-gey:before {
    content: '\ead7';
}

/* '' */
.iconsminds-heart:before {
    content: '\ead8';
}

/* '' */
.iconsminds-homosexual:before {
    content: '\ead9';
}

/* '' */
.iconsminds-inifity:before {
    content: '\eada';
}

/* '' */
.iconsminds-lesbian:before {
    content: '\eadb';
}

/* '' */
.iconsminds-lesbians:before {
    content: '\eadc';
}

/* '' */
.iconsminds-love:before {
    content: '\eadd';
}

/* '' */
.iconsminds-male-1:before {
    content: '\eade';
}

/* '' */
.iconsminds-men:before {
    content: '\eadf';
}

/* '' */
.iconsminds-no-smoking:before {
    content: '\eae0';
}

/* '' */
.iconsminds-paw:before {
    content: '\eae1';
}

/* '' */
.iconsminds-quotes:before {
    content: '\eae2';
}

/* '' */
.iconsminds-redirect:before {
    content: '\eae3';
}

/* '' */
.iconsminds-ribbon:before {
    content: '\eae4';
}

/* '' */
.iconsminds-venn-diagram:before {
    content: '\eae5';
}

/* '' */
.iconsminds-wheelchair:before {
    content: '\eae6';
}

/* '' */
.iconsminds-women:before {
    content: '\eae7';
}

/* '' */
.iconsminds-instagram:before {
    content: '\eae8';
}

/* '' */
.iconsminds-last-fm:before {
    content: '\eae9';
}

/* '' */
.iconsminds-like:before {
    content: '\eaea';
}

/* '' */
.iconsminds-linkedin-2:before {
    content: '\eaeb';
}

/* '' */
.iconsminds-livejournal:before {
    content: '\eaec';
}

/* '' */
.iconsminds-newsvine:before {
    content: '\eaed';
}

/* '' */
.iconsminds-picasa:before {
    content: '\eaee';
}

/* '' */
.iconsminds-pinterest:before {
    content: '\eaef';
}

/* '' */
.iconsminds-plaxo:before {
    content: '\eaf0';
}

/* '' */
.iconsminds-plurk:before {
    content: '\eaf1';
}

/* '' */
.iconsminds-posterous:before {
    content: '\eaf2';
}

/* '' */
.iconsminds-qik:before {
    content: '\eaf3';
}

/* '' */
.iconsminds-reddit:before {
    content: '\eaf4';
}

/* '' */
.iconsminds-reverbnation:before {
    content: '\eaf5';
}

/* '' */
.iconsminds-rss:before {
    content: '\eaf6';
}

/* '' */
.iconsminds-sharethis:before {
    content: '\eaf7';
}

/* '' */
.iconsminds-skype:before {
    content: '\eaf8';
}

/* '' */
.iconsminds-soundcloud:before {
    content: '\eaf9';
}

/* '' */
.iconsminds-stumbleupon:before {
    content: '\eafa';
}

/* '' */
.iconsminds-technorati:before {
    content: '\eafb';
}

/* '' */
.iconsminds-tumblr:before {
    content: '\eafc';
}

/* '' */
.iconsminds-twitter:before {
    content: '\eafd';
}

/* '' */
.iconsminds-unlike:before {
    content: '\eafe';
}

/* '' */
.iconsminds-ustream:before {
    content: '\eaff';
}

/* '' */
.iconsminds-viddler:before {
    content: '\eb00';
}

/* '' */
.iconsminds-vimeo:before {
    content: '\eb01';
}

/* '' */
.iconsminds-wordpress:before {
    content: '\eb02';
}

/* '' */
.iconsminds-xanga:before {
    content: '\eb03';
}

/* '' */
.iconsminds-yahoo:before {
    content: '\eb04';
}

/* '' */
.iconsminds-yelp:before {
    content: '\eb05';
}

/* '' */
.iconsminds-youtube:before {
    content: '\eb06';
}

/* '' */
.iconsminds-ask:before {
    content: '\eb07';
}

/* '' */
.iconsminds-behance:before {
    content: '\eb08';
}

/* '' */
.iconsminds-bing:before {
    content: '\eb09';
}

/* '' */
.iconsminds-blinklist:before {
    content: '\eb0a';
}

/* '' */
.iconsminds-blogger:before {
    content: '\eb0b';
}

/* '' */
.iconsminds-delicious:before {
    content: '\eb0c';
}

/* '' */
.iconsminds-deviantart:before {
    content: '\eb0d';
}

/* '' */
.iconsminds-digg:before {
    content: '\eb0e';
}

/* '' */
.iconsminds-diigo:before {
    content: '\eb0f';
}

/* '' */
.iconsminds-dribble:before {
    content: '\eb11';
}

/* '' */
.iconsminds-email:before {
    content: '\eb12';
}

/* '' */
.iconsminds-evernote:before {
    content: '\eb13';
}

/* '' */
.iconsminds-facebook:before {
    content: '\eb14';
}

/* '' */
.iconsminds-feedburner:before {
    content: '\eb15';
}

/* '' */
.iconsminds-flickr:before {
    content: '\eb16';
}

/* '' */
.iconsminds-formspring:before {
    content: '\eb17';
}

/* '' */
.iconsminds-forsquare:before {
    content: '\eb18';
}

/* '' */
.iconsminds-friendster:before {
    content: '\eb19';
}

/* '' */
.iconsminds-google:before {
    content: '\eb1a';
}

/* '' */
.iconsminds-gowalla:before {
    content: '\eb1b';
}

/* '' */
.iconsminds-icq:before {
    content: '\eb1c';
}

/* '' */
.iconsminds-imdb:before {
    content: '\eb1d';
}

/* '' */
.iconsminds-speach-bubble:before {
    content: '\eb1e';
}

/* '' */
.iconsminds-speach-bubbles:before {
    content: '\eb1f';
}

/* '' */
.iconsminds-speach-bubble-2:before {
    content: '\eb20';
}

/* '' */
.iconsminds-speach-bubble-3:before {
    content: '\eb21';
}

/* '' */
.iconsminds-speach-bubble-4:before {
    content: '\eb22';
}

/* '' */
.iconsminds-speach-bubble-5:before {
    content: '\eb23';
}

/* '' */
.iconsminds-speach-bubble-6:before {
    content: '\eb24';
}

/* '' */
.iconsminds-speach-bubble-7:before {
    content: '\eb25';
}

/* '' */
.iconsminds-speach-bubble-8:before {
    content: '\eb26';
}

/* '' */
.iconsminds-speach-bubble-9:before {
    content: '\eb27';
}

/* '' */
.iconsminds-speach-bubble-10:before {
    content: '\eb28';
}

/* '' */
.iconsminds-speach-bubble-11:before {
    content: '\eb29';
}

/* '' */
.iconsminds-speach-bubble-12:before {
    content: '\eb2a';
}

/* '' */
.iconsminds-speach-bubble-13:before {
    content: '\eb2b';
}

/* '' */
.iconsminds-speach-bubble-asking:before {
    content: '\eb2c';
}

/* '' */
.iconsminds-speach-bubble-comic-2:before {
    content: '\eb2d';
}

/* '' */
.iconsminds-speach-bubble-comic-3:before {
    content: '\eb2e';
}

/* '' */
.iconsminds-speach-bubble-comic-4:before {
    content: '\eb2f';
}

/* '' */
.iconsminds-speach-bubble-comic:before {
    content: '\eb30';
}

/* '' */
.iconsminds-speach-bubble-dialog:before {
    content: '\eb31';
}

/* '' */
.iconsminds-trekking:before {
    content: '\eb32';
}

/* '' */
.iconsminds-trophy:before {
    content: '\eb33';
}

/* '' */
.iconsminds-weight-lift:before {
    content: '\eb35';
}

/* '' */
.iconsminds-aerobics:before {
    content: '\eb36';
}

/* '' */
.iconsminds-archery:before {
    content: '\eb37';
}

/* '' */
.iconsminds-ballet-shoes:before {
    content: '\eb38';
}

/* '' */
.iconsminds-baseball:before {
    content: '\eb39';
}

/* '' */
.iconsminds-basket-ball:before {
    content: '\eb3a';
}

/* '' */
.iconsminds-bowling:before {
    content: '\eb3b';
}

/* '' */
.iconsminds-box:before {
    content: '\eb3c';
}

/* '' */
.iconsminds-chess:before {
    content: '\eb3d';
}

/* '' */
.iconsminds-cricket:before {
    content: '\eb3e';
}

/* '' */
.iconsminds-dumbbell:before {
    content: '\eb3f';
}

/* '' */
.iconsminds-football:before {
    content: '\eb40';
}

/* '' */
.iconsminds-football-2:before {
    content: '\eb41';
}

/* '' */
.iconsminds-footprint:before {
    content: '\eb42';
}

/* '' */
.iconsminds-footprint-2:before {
    content: '\eb43';
}

/* '' */
.iconsminds-golf:before {
    content: '\eb44';
}

/* '' */
.iconsminds-gymnastics:before {
    content: '\eb45';
}

/* '' */
.iconsminds-hokey:before {
    content: '\eb46';
}

/* '' */
.iconsminds-jump-rope:before {
    content: '\eb47';
}

/* '' */
.iconsminds-life-jacket:before {
    content: '\eb48';
}

/* '' */
.iconsminds-medal:before {
    content: '\eb49';
}

/* '' */
.iconsminds-pilates-2:before {
    content: '\eb4a';
}

/* '' */
.iconsminds-rafting:before {
    content: '\eb4b';
}

/* '' */
.iconsminds-running-shoes:before {
    content: '\eb4c';
}

/* '' */
.iconsminds-skydiving:before {
    content: '\eb4d';
}

/* '' */
.iconsminds-snorkel:before {
    content: '\eb4e';
}

/* '' */
.iconsminds-soccer-ball:before {
    content: '\eb4f';
}

/* '' */
.iconsminds-swimming:before {
    content: '\eb50';
}

/* '' */
.iconsminds-tennis:before {
    content: '\eb51';
}

/* '' */
.iconsminds-tennis-ball:before {
    content: '\eb52';
}

/* '' */
.iconsminds-over-time-2:before {
    content: '\eb53';
}

/* '' */
.iconsminds-sand-watch-2:before {
    content: '\eb54';
}

/* '' */
.iconsminds-stopwatch:before {
    content: '\eb55';
}

/* '' */
.iconsminds-time-backup:before {
    content: '\eb56';
}

/* '' */
.iconsminds-timer:before {
    content: '\eb57';
}

/* '' */
.iconsminds-watch:before {
    content: '\eb58';
}

/* '' */
.iconsminds-24-hour:before {
    content: '\eb59';
}

/* '' */
.iconsminds-alarm-clock-2:before {
    content: '\eb5a';
}

/* '' */
.iconsminds-alarm-clock:before {
    content: '\eb5b';
}

/* '' */
.iconsminds-clock:before {
    content: '\eb5c';
}

/* '' */
.iconsminds-clock-2:before {
    content: '\eb5d';
}

/* '' */
.iconsminds-clock-back:before {
    content: '\eb5e';
}

/* '' */
.iconsminds-clock-forward:before {
    content: '\eb5f';
}

/* '' */
.iconsminds-old-clock:before {
    content: '\eb60';
}

/* '' */
.iconsminds-scooter:before {
    content: '\eb61';
}

/* '' */
.iconsminds-ship:before {
    content: '\eb62';
}

/* '' */
.iconsminds-skateboard:before {
    content: '\eb63';
}

/* '' */
.iconsminds-taxi-sign:before {
    content: '\eb64';
}

/* '' */
.iconsminds-traffic-light:before {
    content: '\eb66';
}

/* '' */
.iconsminds-train:before {
    content: '\eb67';
}

/* '' */
.iconsminds-yacht:before {
    content: '\eb68';
}

/* '' */
.iconsminds-bicycle-1:before {
    content: '\eb6a';
}

/* '' */
.iconsminds-bus-2:before {
    content: '\eb6b';
}

/* '' */
.iconsminds-car:before {
    content: '\eb6c';
}

/* '' */
.iconsminds-gaugage:before {
    content: '\eb6e';
}

/* '' */
.iconsminds-gaugage-2:before {
    content: '\eb6f';
}

/* '' */
.iconsminds-helicopter:before {
    content: '\eb70';
}

/* '' */
.iconsminds-jeep:before {
    content: '\eb71';
}

/* '' */
.iconsminds-jet:before {
    content: '\eb72';
}

/* '' */
.iconsminds-motorcycle:before {
    content: '\eb73';
}

/* '' */
.iconsminds-plane:before {
    content: '\eb74';
}

/* '' */
.iconsminds-road-2:before {
    content: '\eb75';
}

/* '' */
.iconsminds-sailing-ship:before {
    content: '\eb76';
}

/* '' */
.iconsminds-video-tripod:before {
    content: '\eb77';
}

/* '' */
.iconsminds-3d-eyeglasses:before {
    content: '\eb78';
}

/* '' */
.iconsminds-cinema:before {
    content: '\eb79';
}

/* '' */
.iconsminds-director:before {
    content: '\eb7a';
}

/* '' */
.iconsminds-film:before {
    content: '\eb7b';
}

/* '' */
.iconsminds-film-video:before {
    content: '\eb7c';
}

/* '' */
.iconsminds-old-tv:before {
    content: '\eb7d';
}

/* '' */
.iconsminds-tv:before {
    content: '\eb7e';
}

/* '' */
.iconsminds-video:before {
    content: '\eb7f';
}

/* '' */
.iconsminds-video-5:before {
    content: '\eb80';
}

/* '' */
.iconsminds-video-6:before {
    content: '\eb81';
}

/* '' */
.iconsminds-video-len:before {
    content: '\eb82';
}

/* '' */
.iconsminds-sunrise:before {
    content: '\eb83';
}

/* '' */
.iconsminds-sunset:before {
    content: '\eb84';
}

/* '' */
.iconsminds-temperature-2:before {
    content: '\eb85';
}

/* '' */
.iconsminds-thunder:before {
    content: '\eb86';
}

/* '' */
.iconsminds-umbrella-2:before {
    content: '\eb87';
}

/* '' */
.iconsminds-wave:before {
    content: '\eb88';
}

/* '' */
.iconsminds-wind-turbine:before {
    content: '\eb89';
}

/* '' */
.iconsminds-windy:before {
    content: '\eb8a';
}

/* '' */
.iconsminds-cloud-hail:before {
    content: '\eb8b';
}

/* '' */
.iconsminds-cloud-moon:before {
    content: '\eb8c';
}

/* '' */
.iconsminds-cloud-rain:before {
    content: '\eb8d';
}

/* '' */
.iconsminds-cloud-snow:before {
    content: '\eb8e';
}

/* '' */
.iconsminds-cloud-sun:before {
    content: '\eb8f';
}

/* '' */
.iconsminds-cloud-weather:before {
    content: '\eb90';
}

/* '' */
.iconsminds-drop:before {
    content: '\eb91';
}

/* '' */
.iconsminds-dry:before {
    content: '\eb92';
}

/* '' */
.iconsminds-fog-day:before {
    content: '\eb93';
}

/* '' */
.iconsminds-fog-night:before {
    content: '\eb94';
}

/* '' */
.iconsminds-half-moon:before {
    content: '\eb95';
}

/* '' */
.iconsminds-rain-drop:before {
    content: '\eb96';
}

/* '' */
.iconsminds-snow:before {
    content: '\eb97';
}

/* '' */
.iconsminds-snowflake-3:before {
    content: '\eb98';
}

/* '' */
.iconsminds-snow-storm:before {
    content: '\eb99';
}

/* '' */
.iconsminds-spring:before {
    content: '\eb9a';
}

/* '' */
.iconsminds-storm:before {
    content: '\eb9b';
}

/* '' */
.iconsminds-summer:before {
    content: '\eb9c';
}

/* '' */
.iconsminds-sun:before {
    content: '\eb9d';
}

/* '' */
.iconsminds-sun-cloudy-rain:before {
    content: '\eb9e';
}

/* '' */
.iconsminds-electric-guitar:before {
    content: '\eb9f';
}

/* '' */
.iconsminds-guitar:before {
    content: '\eba0';
}

/* '' */
.iconsminds-air-balloon-1:before {
    content: '\eba1';
}

/* '' */
.iconsminds-tractor:before {
    content: '\eba2';
}

/* '' */
.iconsminds-calendar-1:before {
    content: '\eba3';
}

/* '' */
.iconsminds-calendar-4:before {
    content: '\eba4';
}

/* '' */
.iconsminds-trophy-2:before {
    content: '\eba5';
}

/* '' */
.iconsminds-life-safer:before {
    content: '\eba6';
}

/* '' */
.iconsminds-calculator:before {
    content: '\eba7';
}

/* '' */
.iconsminds-taj-mahal:before {
    content: '\eba8';
}

/* '' */
.iconsminds-scale:before {
    content: '\eba9';
}

/* '' */
.iconsminds-usb:before {
    content: '\ebaa';
}

/* '' */
.iconsminds-flowerpot:before {
    content: '\ebab';
}

/* '' */
.iconsminds-shop-4:before {
    content: '\ebac';
}

/* '' */
.iconsminds-line-chart-1:before {
    content: '\ebad';
}

/* '' */
.iconsminds-line-chart-3:before {
    content: '\ebae';
}

/* '' */
.iconsminds-forest-1:before {
    content: '\ebaf';
}

/* '' */
.iconsminds-pantone:before {
    content: '\ebb0';
}

/* '' */
.iconsminds-digital-drawing:before {
    content: '\ebb1';
}

/* '' */
.iconsminds-credit-card:before {
    content: '\ebb2';
}

/* '' */
.iconsminds-credit-card-3:before {
    content: '\ebb3';
}

/* '' */
.iconsminds-money-bag:before {
    content: '\ebb4';
}

/* '' */
.iconsminds-printer:before {
    content: '\ebb5';
}

/* '' */
.iconsminds-sheep:before {
    content: '\ebb8';
}

/* '' */
.iconsminds-cow:before {
    content: '\ebb9';
}

/* '' */
.iconsminds-dog:before {
    content: '\ebba';
}

/* '' */
.iconsminds-deer:before {
    content: '\ebbb';
}

/* '' */